import React, { useEffect } from 'react';

import { handlePromptScrollShadow } from '../../../Shared/utils';


export default function PromptWrapper({children}) {
  useEffect(() => {
    window.addEventListener('resize', handlePromptScrollShadow);
    return () => window.removeEventListener('resize', handlePromptScrollShadow);
  }, []);

  return (
    <div className="prompt__wrapper" onScroll={handlePromptScrollShadow}>
      {children}
    </div>
  );
}
