import axios from 'axios';

import { apiUrl, config } from '../Shared/config';


export const login = async (user, remember) => {
  try {
    // console.log("8 - login=", user, remember);
    const { data } = await axios.post(apiUrl + 'user/login', { user, remember}, config);

    return data.success ? data : data?.error;

  } catch (error) { return error.response?.data?.error || 'error'; }
};


export const logout = async () => {
  try {
    const { data } = await axios.get(apiUrl + 'user/logout', config);

    return data;

  } catch (error) { return error.response?.data?.error || 'logout'; }
};


export const forgotPw = async (email, lang) => {
  try {
    const { data } = await axios.post(apiUrl + 'user/forgot', { email, lang }, config);

    return data.success ? data : data?.error;

  } catch (error) { return error.response?.data?.error || 'error'; }
};


export const resetPw = async ({ password, passCheck, token }) => {
  try {
    const { data } = await axios.put(apiUrl + 'user/reset/' + token.replace(' ', ''), { password, passCheck }, config);

    return data.success ? data : data?.error;

  } catch (error) { return error.response?.data?.error || 'error'; }
};
