import copy from 'fast-copy';


export const mainReducer = (state, action) => {

  const newState = {};
  for (const key of Object.keys(action)) newState[key] = action[key];

  const finalState = copy({...state, ...newState});
  localStorage.setItem('mainState', JSON.stringify(
    finalState
  ));

  return finalState;

};
