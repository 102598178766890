import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Header from '../Components/Dashboard/Header/Header';
// import Sidebar from '../Components/Dashboard/Sidebar/Sidebar';
import Main from '../Components/Dashboard/Main/Main';

// import ReportingSettings from '../Components/Dashboard/Reporting/Settings/Settings';
// import EditScenario from '../Components/Dashboard/Reporting/Settings/Scenarios/Update';

// import { roles } from '../Shared/utils';
// import { useAppContext } from '../Context/App/Provider';


export default function MainPage() {
  // const [{ user }] = useAppContext();

  return (
    <>
      <Header />
      <div className="body">

        <Switch>
          <Route exact path='/main'>
            {/* <Sidebar /> */}
            <Main />
          </Route>

          {/* {user.role !== roles.user && (
            <Route exact path='/reporting/settings'>
              <div className="reporting__settings">
                <ReportingSettings />
              </div>
            </Route>
          )}
          {user.role !== roles.user && (
            <Route exact path='/reporting/settings/scenario/:id'>
              <div className="reporting__settings">
                <EditScenario />
              </div>
            </Route>
          )} */}

          <Route render={() => <Redirect to='/main' />} />
        </Switch>
      </div>
    </>
  );
}
