import axios from 'axios';

import { apiUrl, config } from '../Shared/config';



export const searchWords = async (words) => {
  try {
    const { data } = await axios.post(apiUrl + 'main/search', { words }, config);

    return data.success ? data : data?.error;

  } catch (error) { return error.response?.data?.error || 'error'; }
};



// export const getGroups = async () => {
//   try {
//     const { data } = await axios.get(apiUrl + 'reporting/groups', config);

//     return data.success ? data : data?.error;

//   } catch (error) { return error.response?.data?.error || 'error'; }
// };


// export const getLocations = async (userId = null, groupId = null) => {
//   try {
//     const { data } = await axios.get(apiUrl + 'reporting/locations' + (
//       userId && groupId ? `/user=${userId}&group=${groupId}` : ''
//     ), config);

//     return data.success ? data : data?.error;

//   } catch (error) { return error.response?.data?.error || 'error'; }
// };


// export const getDeployments = async ({ mode, scenario, module, startDate, endDate, groups, locations, offset, size }) => {

//   // console.log("30 -...............");
//   // console.log("30 - mode=", mode);
//   // console.log("31 - scenario=", scenario);
//   // console.log("32 - module=", module);
//   // console.log("33 - startDate=", startDate);
//   // console.log("34 - endDate=", endDate);

//   try {
//     const { data } = await axios.post(apiUrl + 'reporting/deployments', {
//       mode, scenario, module, startDate, endDate, groups, locations, offset, size
//     }, config);

//     return data.success ? data : data?.error;

//   } catch (error) { return error.response?.data?.error || 'error'; }
// };


// export const getRealtimeDeployments = async (locations) => {
//   try {
//     const { data } = await axios.post(apiUrl + 'reporting/realtime', { locations }, config);

//     return data.success ? data : data?.error;

//   } catch (error) { return error.response?.data?.error || 'error'; }
// };


// export const getDeploymentsHistory = async (deployment, usage = 0) => {
//   try {
//     const { data } = await axios.post(apiUrl + 'reporting/deployments/history', { deployment, usage }, config);

//     return data.success ? data : data?.error;

//   } catch (error) { return error.response?.data?.error || 'error'; }
// };


// export const getReports = async () => {
//   try {
//     const { data } = await axios.post(apiUrl + 'reporting/reports', {}, config);

//     return data.success ? data : data?.error;

//   } catch (error) { return error.response?.data?.error || 'error'; }
// };


// export const getReportGenerated = async (deployment, usage = 0, selectedReport) => {
//   try {
//     const { data } = await axios.post(apiUrl + 'reporting/deployments/reportgenerator', { deployment, usage, selectedReport }, config);

//     return data.success ? data : data?.error;

//   } catch (error) { return error.response?.data?.error || 'error'; }
// };


// export const getSDSQuestions = async ({ locations }) => {
//   try {
//     const { data } = await axios.post(apiUrl + 'reporting/sdsquestions', { locations }, config);

//     return data.success ? data : data?.error;

//   } catch (error) { return error.response?.data?.error || 'error'; }
// };