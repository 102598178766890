import React, { createContext, useContext, useReducer } from 'react';


const mainState = (
  localStorage.getItem('mainState')
    ?
  {
    ...JSON.parse(localStorage.getItem('mainState'))
  }
    :
  {
    search: 'xnull'
//...
  }
);

export const MainContext = createContext();
export const MainProvider = ({ reducer, children }) => (
  <MainContext.Provider value={useReducer(reducer, mainState)}>
    {children}
  </MainContext.Provider>
);

export const useMainContext = () => useContext(MainContext);
