import axios from 'axios';

import { apiUrl, config } from '../Shared/config';


export const get = async (params) => {
  try {
    // console.log("8 - get=", params, apiUrl);

    const { data } = await axios.get(apiUrl + `admin/${params.type}`, config);
    // console.log("9 - get=", params, data);
    return data.success ? data : data?.error;

  } catch (error) { return error.response?.data?.error || 'load'; }
};


export const create = async (params) => {
  // console.log("17 - create=", params, config);
  try {
    const { data } = await axios.post(apiUrl + `admin/${params.type}`, params[params.type], config);

    return data.success ? data : data?.error;

  } catch (error) {
    console.log("23 - error=", error);
    return error.response?.data?.error || 'create';
  }
};


export const update = async (params) => {
  try {
    console.log("params=", params);
    console.log("params[params.type]=", params[params.type]);

    const { data } = await axios.put(apiUrl + `admin/${params.type}`, params[params.type], config);

    return data.success ? data : data?.error;

  } catch (error) { return error.response?.data?.error || 'update'; }
};


export const remove = async (params) => {
  try { // add database parameter when deleting a location
    const { data } = await axios.delete(apiUrl + `admin/${params.type}/${params.id}`, config);

    return data.success ? data : data?.error;

  } catch (error) { return error.response?.data?.error || 'delete'; }
};



// export const getTemplates = async () => {
//   try {
//     const { data } = await axios.post(apiUrl + 'admin/template', {
//     }, config);

//     return data.success ? data : data?.error;

//   } catch (error) {
//     console.log("82 - error=", error);
//     return error.response?.data?.error || 'error';
//   }
// };

// export const logInAs = async (userId) => {
//   try {
//     const { data } = await axios.post(apiUrl + `admin/loginas`, { userId }, config);
//     return data.success ? data : data?.error;
//   }
//   catch (error) {
//     console.log("58 - error=", error);
//     return error.response?.data?.error || 'loginas';
//   }
// };


// export const getReports = async () => {
//   try {
//     const { data } = await axios.get(apiUrl + 'admin/report', config);

//     return data.success ? data : data?.error;

//   } catch (error) { return error.response?.data?.error || 'error'; }
// };


// export const getTemplates = async () => {
//   try {
//     const { data } = await axios.post(apiUrl + 'admin/template', {
//     }, config);

//     return data.success ? data : data?.error;

//   } catch (error) {
//     console.log("82 - error=", error);
//     return error.response?.data?.error || 'error';
//   }
// };


// export const uploadTemplate = async (fileData /*filename, template*/) => {

//   // console.log("74 - filename=", filename);
//   try {
//     const { data } = await axios.post(apiUrl + `admin/uploadTemplate`, { fileData /*, filename, template*/ }, config);
//     return data.success ? data : data?.error;
//   }
//   catch (error) {
//     console.log("96 - error=", error);
//     return error.response?.data?.error || 'update';
//   }
// };

// export const downloadTemplate = async (template) => {

//   console.log("87 - template=", template);
//   try {
//     const { data } = await axios.post(apiUrl + `admin/downloadTemplate`, { template }, config);
//     return data.success ? data : data?.error;
//   }
//   catch (error) {
//     console.log("109 - error=", error);
//     return error.response?.data?.error || 'update';
//   }
// };

