import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableRow, TableCell, Tabs, Tab, Box, AppBar, TextField, withStyles, Button } from '@material-ui/core';

import { useAppContext } from '../../../../Context/App/Provider';
import { useSourcesContext } from '../../../../Context/Sources/Provider';
import './sourceDetails.css';

import { getTimeAsZDateTime } from '../../../../Functions/utils';

import Typography from "@material-ui/core/Typography";
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../Shared/utils';

import { update } from '../../../../Functions/admin';

export default function SourceDetail({ keyX, arraySource, /*expanded, setExpanded,*/ handleDelete, setSelectedSource }) {

  const key = keyX;

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <>
            <Box p={3}>
              <Typography>{children}</Typography>
            </Box>

          </>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`
    };
  }


  const StyledTabs = withStyles({
    indicator: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      '& > span': {
        maxWidth: 40,
        width: '100%',
        backgroundColor: colors.dh,
        padding: '2px'
      },
    },
  })((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);


  const StyledTab = withStyles((theme) => ({
    root: {
      textTransform: 'none',
      fontWeight: 'bold',
      fontSize: '100%',
      "&.Mui-selected": {
        background: 'var(--button-bg-darker)',
        color: '#000',
      }
    },
  }))((props) => <Tab disableRipple {...props} />);


  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      display: "flex"
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
      overflow: 'visible'
    }
  }));
  const tabClasses = useStyles();

  // const history = useHistory();
  const [{ settings }] = useAppContext();
  const { t } = useTranslation();

  const [{ expanded, tabValueId }, sourceDispatch] = useSourcesContext();

  const [tabValue, setTabValue] = useState(() => {
    let ret = 0;
    if (tabValueId) {
      let indexOfTabValue = arraySource.findIndex(s => s.id === tabValueId);
      if (indexOfTabValue !== -1)
        ret = indexOfTabValue;
    }
    return ret;
  });
  const [loading, setLoading] = useState(false);



  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if (arraySource[newValue] && arraySource[newValue].id) {
      sourceDispatch({ tabValueId: arraySource[newValue].id });
    }
  };


  const open = expanded.includes(arraySource[0].id);

  const toggleSource = (source) => (
    // setExpanded(open ? expanded.filter(s => s !== source.id) : [...expanded, source.id])
    sourceDispatch({ expanded: open ? expanded.filter(s => s !== source.id) : [...expanded, source.id] })
  );


  const toggleInclude = (source) => {
    setLoading(true);
    source.isHidden = !source.isHidden;

    // locations: null -> Skip adding / removing locations on account update
    update({ type: 'source_onlyHidden', source_onlyHidden: { ...source } }).then(res => {
      setLoading(false);
      if (!res.success) {
        source.isHidden = !source.isHidden;
      }
    });
  };



  let selectedTxt = "";
  // if (deployment.deployment_id === selected)
  // selectedTxt = "_selected";



  // if (arraySource[0].type === 10) {
  //   const options = {
  //     year: 'numeric',
  //     month: '2-digit',
  //     day: '2-digit'
  //   };
  // }

  // console.log("134 - tabValue=", tabValue, arraySource);

  return (
    <div className={'container ' + (open ? 'open' + selectedTxt : 'closed' + selectedTxt)}>
      <div className="container__header">
        <div className="container__title" onClick={() => toggleSource(arraySource[0])}>
          <span className="material-icons">arrow_{open ? 'drop_down' : 'right'}</span>
          {/* <span>{t('sources.types.' + arraySource[0].type)+'\u2000\u2013\u2000'}</span> */}


          {(arraySource[0].type === 10) && (
            <>
              <div style={{ fontWeight: 'bold', fontStyle: "italic" /*, textDecoration: "line-through"*/ }}>
                {arraySource[0].intitule}
              </div>
              <span>{'\u2000\u2013\u2000' + t('sources.columns.article')}</span>
              <div style={{ fontWeight: 'bold', fontStyle: "italic" }}>
                {arraySource[0].article}
              </div>
            </>
          )}

          {(arraySource[0].type === 20) && (
            <>
              <div style={{ fontWeight: 'bold', fontStyle: "italic" }}>
                {arraySource[0].intitule}
              </div>
              <span>{'\u2000\u2013\u2000' + t('sources.columns.article')}</span>
              <div style={{ fontWeight: 'bold', fontStyle: "italic" }}>
                {arraySource[0].article}
              </div>
            </>
          )}

          {(arraySource[0].type === 30 && arraySource[0].juridiction === 10) && (
            <>
              <div style={{ fontWeight: 'bold', fontStyle: "italic" }}>
                {t('sources.juridiction.' + arraySource[0].juridiction)}
              </div>
              <span>{'\u2000\u2013\u2000' + t('sources.columns.numero')}</span>
              <div style={{ fontWeight: 'bold', fontStyle: "italic" }}>
                {arraySource[0].numero}
              </div>
            </>
          )}

          {(arraySource[0].type === 30 && arraySource[0].juridiction === 20) && (
            <>
              <div style={{ fontWeight: 'bold', fontStyle: "italic" }}>
                {t('sources.juridiction.' + arraySource[0].juridiction)}
              </div>
              <span>{'\u2000\u2013\u2000' + t('sources.columns.ville')}</span>
              <div style={{ fontWeight: 'bold', fontStyle: "italic" }}>
                {t('global.villes.' + arraySource[0].ville)}
              </div>
              <span>{'\u2000\u2013\u2000' + t('sources.columns.numero')}</span>
              <div style={{ fontWeight: 'bold', fontStyle: "italic" }}>
                {arraySource[0].numero}
              </div>
            </>
          )}

          {(arraySource[0].type === 50) && (
            <>
              <div style={{ fontWeight: 'bold', fontStyle: "italic" }}>
                {arraySource[0].intitule}
              </div>
              <span>{'\u2000\u2013\u2000' + t('sources.columns.auteur')}</span>
              <div style={{ fontWeight: 'bold', fontStyle: "italic" }}>
                {arraySource[0].auteur}
              </div>
            </>
          )}

          {(arraySource[0].type === 60) && (
            <>
              <div style={{ fontWeight: 'bold', fontStyle: "italic" }}>
                {arraySource[0].intitule}
              </div>
              <span>{'\u2000\u2013\u2000' + t('sources.columns.auteur')}</span>
              <div style={{ fontWeight: 'bold', fontStyle: "italic" }}>
                {arraySource[0].auteur}
              </div>
            </>
          )}


        </div>
      </div>

      <div className="container__content">
        <div className={tabClasses.root}>
          <StyledTabs
            orientation="vertical"
            variant="scrollable"
            value={tabValue}
            onChange={handleTabChange}
            aria-label="Vertical tabs example"
            className={tabClasses.tabs}
          >
            {(arraySource.map((source, key1) => {

              //default options
              let options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
              };

              // specific 
              if (source.type === 10 || source.type === 20)
                options = {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit'
                };

              const strDate = new Date(getTimeAsZDateTime(source.date)).toLocaleDateString(settings.lang ? settings.lang : 'fr', options);
              return (
                <StyledTab
                  key={key1}
                  label={strDate}
                  {...a11yProps(key)}
                />
              )
            }
            ))}
          </StyledTabs>

          {(arraySource.map((source, key2) => {

            //default options
            let options = {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            };

            // specific 
            if (source.type === 10 || source.type === 20)
              options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
              };

            // const strDate = new Date(getTimeAsZDateTime(source].date)).toLocaleDateString(settings.lang ? settings.lang : 'fr', options);


            return (
              <>
                <TabPanel key={key2} style={{ width: '100%' }} value={tabValue} index={key2}>

                  {/* <div>
                  <span style={{ fontWeight: 'bold' }}>
                    {t('sources.columns.date') + " : "}
                  </span>
                  <span style={{ fontSize: '90%' }}>
                    {strDate}
                  </span>
                </div>

                <br /> */}

                  <div>
                    {(source.ordrePublic === true) && (
                      <span
                        className={"material-icons"}
                        style={{
                          textAlign: 'left',
                          fontSize: '100%',
                          color: '#f1c40f'
                        }}
                        title={t('sources.columns.ordre_public')}
                      >
                        {'gavel'}
                      </span>
                    )}

                    {/* <span style={{ fontWeight: 'bold', fontStyle: "italic" }}>
                      {t('sources.columns.texte') + " : "}
                    </span> */}

                    {(source.cass_rejet === 10) && (
                      <span>
                        <span>{"("}</span>
                        <span

                          style={{
                            textAlign: 'left',
                            fontSize: '100%',
                            // color: '#22b222'
                          }}
                          title={t('sources.columns.cass_rejet')}
                        >
                          {t('sources.cass_rejet.10')}
                        </span>
                        <span>{") "}</span>
                      </span>
                    )}

                    {(source.cass_rejet === 20) && (
                      <span>
                        <span>{"("}</span>
                        <span

                          style={{
                            textAlign: 'left',
                            fontSize: '100%',
                            // color: '#ff6633'
                          }}
                          title={t('sources.columns.cass_rejet')}
                        >
                          {t('sources.cass_rejet.20')}
                        </span>
                        <span>{") "}</span>
                      </span>
                    )}

                  </div>

                  <div style={{ width: '100%', wordWrap: 'break-word', whiteSpace: "pre-wrap", fontSize: '90%' }}>
                    {source.texte}
                  </div>

                  {(source.mots_supp !== "" && (
                    <>
                      <br />
                      <div style={{ fontWeight: 'bold', fontStyle: "italic" }}>{t('sources.columns.mots_supp') + " : "}</div>
                      <div style={{ width: '100%', wordWrap: 'break-word', whiteSpace: "pre-wrap", fontSize: '90%' }}>
                        {source.mots_supp}
                      </div>
                    </>
                  ))}

                  {(source.lien !== "" && (
                    <>
                      <br />
                      <div style={{ fontWeight: 'bold', fontStyle: "italic" }}>{t('sources.columns.lien') + " : "}</div>
                      <div style={{ width: '100%', wordWrap: 'break-word', whiteSpace: "pre-wrap", fontSize: '90%' }}>
                        <a href={source.lien} target="_blank">{source.lien}</a>
                      </div>
                    </>

                  ))}

                  {(source.commentaires !== "" && (
                    <>
                      <br />
                      <div style={{ fontWeight: 'bold', fontStyle: "italic" }}>{t('sources.columns.commentaires') + " : "}</div>
                      <div style={{ width: '100%', wordWrap: 'break-word', whiteSpace: "pre-wrap", fontSize: '90%' }}>
                        {source.commentaires}
                      </div>
                    </>
                  ))}

                  <div style={{ display: "flex", height: '0px', width: '100%', justifyContent: "left" }}>
                    <span className="options" >
                      <Button
                        style={{ color: "#aaa", display: (setSelectedSource || handleDelete) ? 'default' : 'none' }}
                        className="button"
                        title={source.isHidden === true ? t('sources.include.button_yes') : t('sources.include.button_no')}
                        onClick={() => {
                          console.log("406 - click...=", source);
                          toggleInclude(source);
                        }}
                        startIcon={
                          (source.isHidden === true) ? (
                            <span className="material-icons-outlined">
                              visibility
                            </span>
                          ) : (
                            <span className="material-icons-outlined" >
                              visibility_off
                            </span>
                          )}
                      >
                        <span >{source.isHidden === true ? t('sources.include.button_yes') : t('sources.include.button_no')}</span>
                      </Button>
                    </span>
                  </div>

                  <div style={{ display: "flex", height: '0px', width: '100%', justifyContent: "right" }}>
                    <span className="options">
                      <Button
                        style={{ display: (setSelectedSource) ? 'default' : 'none' }}
                        className="button"
                        title={t('global.update')}
                        onClick={() => {
                          console.log("341 - click...=", source);
                          setSelectedSource(source)
                        }}
                        startIcon={<span className="material-icons-outlined" style={{ color: '#222' }}>edit</span>}
                      >
                        <span>{t('sources.update.button')}</span>
                      </Button>

                      <Button
                        style={{ display: (handleDelete) ? 'default' : 'none' }}
                        className="button"
                        title={t('global.delete')}
                        onClick={() => {
                          handleDelete(source)
                        }}
                        startIcon={<span className="material-icons-outlined" style={{ color: '#f22' }}>delete</span>}
                      >

                        <span>{t('sources.delete.button')}</span>
                      </Button>

                    </span>
                  </div>

                </TabPanel >
              </>

            )
          }
          ))}


          {/* <TabPanel value={tabValue} index={0}>

          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            Item Two
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            Item Three
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            Item Four
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
            Item Five
          </TabPanel>
          <TabPanel value={tabValue} index={5}>
            Item Six
          </TabPanel>
          <TabPanel value={tabValue} index={6}>
            Item Seven
          </TabPanel> */}
        </div>

      </div >
    </div >
  );
}
