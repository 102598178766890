import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BeatLoader from 'react-spinners/BeatLoader';
import { MultiSelect } from 'react-multi-select-component';
import { Button, TextField, InputAdornment } from '@material-ui/core';

import Prompt from '../../../Shared/Prompt/Prompt';
import PromptWrapper from '../../../Shared/Prompt/Wrapper';
import PromptActions from '../../../Shared/Prompt/Actions';

import { roles } from '../../../../Shared/utils';
import { update } from '../../../../Functions/admin';
import { useAppContext } from '../../../../Context/App/Provider';
import { multiSelectStrings } from '../../../../Functions/utils';


export default function UpdateAccount({ users, setUsers, selectedUser, setSelectedUser }) {
  const { t } = useTranslation();
  const [{ user }] = useAppContext();

  const [loading, setLoading] = useState(false);
  const [newUser, setNewUser] = useState({
    ...selectedUser, // if loc.group doesn't exist -> not a location
    oldLogin: selectedUser.login
  });


  const handleEditUser = (e, user) => {
    e.preventDefault();
    setLoading(true);

    console.log("33 - user=", user);

    // Only set new contact email if changed
    //user.contactEmailActivated = user.contactEmail ? true : false;
    if (user.email === selectedUser.email)
      user.email = null;

    update({ type: 'me', me: user }).then(res => {
      setLoading(false);
      if (!res.success) return toast(t('admin.accounts.errors.' + res, { email: user.login }), { type: 'error' });

      const newUsers = users.slice(); // .slice() creates a new array
      for (const [index, row] of newUsers.entries()) {
        if (row.id === res.user.id) {
          // newUsers[index] = { ...res.user, role: t('admin.accounts.types.' + res.user.role) }; break;
          newUsers[index] = { ...res.user, role: res.user.role }; break;
        }
      }

      // update default accounts sorting in case of username change
      setUsers(newUsers.sort((a, b) => a.login.localeCompare(b.login)));

      setSelectedUser(null);
      toast(t('admin.accounts.update.success', { login: user.login }), { type: 'success' });
    });
  };

  console.log("59 - newUser=", newUser);

  return (
    <Prompt animated>
      <form className="prompt__container animated" onSubmit={e => handleEditUser(e, newUser)}>
        <PromptWrapper>
          <div className="prompt__title">
            <h1 title={selectedUser.login}>{t('admin.accounts.update.title', { login: selectedUser.login })}</h1>            
          </div>

          <div className="prompt__content">

            <TextField
              InputProps={{
                startAdornment: <InputAdornment position="start">
                  {/* <img style={{ width: "24px" }} src="/assets/images/icons_png/account_circle.png" alt=""></img> */}
                  <span className="material-icons-outlined">account_circle</span>
                </InputAdornment>
              }}
              required
              name="no-username"
              autoComplete="off"
              variant="filled"
              value={newUser.login}
              label={t('admin.accounts.columns.login')}
              onChange={e => setNewUser({ ...newUser, login: e.target.value })}
              size="small"
            />

            <TextField
              InputProps={{
                startAdornment: <InputAdornment position="start">
                  {/* <img style={{ width: "24px" }} src="/assets/images/icons_png/email.png" alt=""></img> */}
                  <span className="material-icons-outlined">email</span>
                </InputAdornment>
              }}
              name="no-email"
              autoComplete="off"
              variant="filled"
              value={newUser.email ?? ''}
              label={t('admin.accounts.columns.email')}
              onChange={e => setNewUser({ ...newUser, email: e.target.value })}
              size="small"
            />

            <div className="flexRow">
              <TextField
                InputProps={{
                  startAdornment: <InputAdornment position="start">
                    {/* <img style={{ width: "24px" }} src="/assets/images/icons_png/lock.png" alt=""></img> */}
                    <span className="material-icons-outlined">lock</span>
                  </InputAdornment>
                }}
                type='password'
                variant="filled"
                label={t('reset.field1')}
                value={newUser.password ?? ''}
                onChange={e => setNewUser({ ...newUser, password: e.target.value })}
                placeholder={newUser.passCheck ? '' : t('admin.accounts.update.leave_blank')}
                size="small"
              />

              <TextField
                InputProps={{
                  startAdornment: <InputAdornment position="start">
                    {/* <img style={{ width: "24px" }} src="/assets/images/icons_png/lock.png" alt=""></img> */}
                    <span className="material-icons-outlined">lock</span>
                  </InputAdornment>
                }}
                type='password'
                variant="filled"
                label={t('reset.field2')}
                value={newUser.passCheck ?? ''}
                onChange={e => setNewUser({ ...newUser, passCheck: e.target.value })}
                placeholder={newUser.password ? '' : t('admin.accounts.update.leave_blank')}
                size="small"
              />
            </div>
          </div>
        </PromptWrapper>

        <PromptActions>
          <Button className="btnSecondary" onClick={() => setSelectedUser(null)}>
            {t('global.cancel')}
          </Button>

          <Button className="btnPrimary" type="submit" disabled={loading}>
            {loading ? <BeatLoader color="white" css="transform:translateY(4px)" /> : t('global.save')}
          </Button>
        </PromptActions>
      </form>
    </Prompt>
  );
}
