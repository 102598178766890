import React from 'react';


export default function Prompt({children, animated, style}) {
  return (
    <div className={'prompt__backdrop ' + (animated ? 'animated' : '')} style={style}>
      {children}
    </div>
  );
}
