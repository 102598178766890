import React, { createContext, useContext, useReducer } from 'react';

const adminState = (
  localStorage.getItem('adminState')
    ?
    JSON.parse(localStorage.getItem('adminState'))
    :
    {
      accountColumns: {
        id: { hide: true, order: 1, width: 100 },
        isActive: { hide: false, order: 2, width: 100 },
        login: { hide: false, order: 3, width: 200 },
        email: { hide: false, order: 4, width: 250 },
        latestConnection: { hide: false, order: 5, width: 250 },
        role: { hide: false, order: 6, width: 250 },
        actions: { hide: false, order: 8, width: 125 }
      }
    }
);

const AdminContext = createContext();
export const AdminProvider = ({ reducer, children }) => (
  <AdminContext.Provider value={useReducer(reducer, adminState)}>
    {children}
  </AdminContext.Provider>
);

export const useAdminContext = () => useContext(AdminContext);