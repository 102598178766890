import React, { useEffect } from 'react';

import { handlePromptScrollShadow } from '../../../Shared/utils';


export default function PromptActions({children, column}) {
  useEffect(() => {
    handlePromptScrollShadow(true);
  }, []);

  return (
    <div className={'prompt__actions flexRow ' + (column ? 'column' : '')}>
      {children}
    </div>
  );
}
