export const apiUrl = process.env.REACT_APP_NODE_ENV === 'deker_harle' ?
  'https://anselme.deker-harle.fr:9000/api/v1/' :
  (
    process.env.REACT_APP_NODE_ENV === 'lan' ?
      'https://192.168.9.203:9000/api/v1/' :

      'https://localhost:9000/api/v1/'
  )

export const config = {
  headers: {
    'Content-Type': 'application/json',
    'dashboard': process.env.REACT_APP_API_KEY
  },
  withCredentials: true,
  credentials: 'include'
};
