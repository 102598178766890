import Switch from 'react-switch';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

import { Button, LinearProgress as LoadingOverlay } from '@material-ui/core';

import {
  DataGrid, useGridApiRef, GridFooter, GridFilterInputValue, frFR, enUS,
  GridToolbarContainer, GridToolbarFilterButton, GridToolbarColumnsButton
} from '@mui/x-data-grid';

import Create from './Create';
import Update from './Update';

import SourceDetail from './Details/sourceDetails'

import { useAppContext } from '../../../Context/App/Provider';
import { useSourcesContext } from '../../../Context/Sources/Provider';


import { get, remove } from '../../../Functions/admin';

import { colors, roles, useStateWithCallback } from '../../../Shared/utils';
import { changeSourcesColumns, changeSettings, activeGridFilter, translatedGridFilters } from '../../../Functions/utils';

// import { logout } from '../../../../Functions/auth';

import './Sources.css';


export default function SourcesList() {

  const history = useHistory();
  const { t } = useTranslation();

  const [{ settings, user }, appDispatch] = useAppContext();
  const [{ expanded, typeExpanded }, sourceDispatch] = useSourcesContext();


  //dispatch({ selected: { groups: [], locations: [] } });
  // const [expanded, setExpanded] = useState([]);
  // const [typeExpanded, setTypeExpanded] = useState([]);


  const [sources, setSources] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedSource, setSelectedSource] = useState(null);
  const [model, setModel] = useStateWithCallback({ filtered: null, selected: [] });

  const [loaded, setLoaded] = useState(false);


  const toggleAllSources = () => {
    if (expanded.length === (mapLoi.size + mapDecret.size + mapJustice.size + mapDoctrine.size + mapAutre.size) && typeExpanded.length === 5) {
      sourceDispatch({ expanded: [], typeExpanded: [] });
      return;
    }

    let arrayAll = new Array();
    Array.from(mapLoi.values()).map((element, key) => {
      arrayAll.push(element[0]);
    });
    Array.from(mapDecret.values()).map((element, key) => {
      arrayAll.push(element[0]);
    });
    Array.from(mapJustice.values()).map((element, key) => {
      arrayAll.push(element[0]);
    });
    Array.from(mapDoctrine.values()).map((element, key) => {
      arrayAll.push(element[0]);
    });
    Array.from(mapAutre.values()).map((element, key) => {
      arrayAll.push(element[0]);
    });


    /*
        "10": "Loi",
        "20": "Décret",
        "30": "Décision de justice",
        "40": "Règlement type",
        "50": "Doctrine",
        "60": "Autre"
    */
    // sourceDispatch({
    //   expanded: expanded.length === arrayAll.length ? [] : arrayAll.map(s => s.id),
    //   typeExpanded: typeExpanded.length === 5 ? [] : [10, 20, 30, 50, 60]
    // });

    sourceDispatch({
      expanded: arrayAll.map(s => s.id),
      typeExpanded: [10, 20, 30, 50, 60]
    });
  };


  const handleDelete = (source) => {
    if (window.confirm(t('sources.delete.confirm', { sourceDate: source.date }))) {
      setLoading(true);

      remove({ type: 'source', id: source.id }).then(res => {
        setLoading(false);
        if (!res.success) return toast(t('sources.errors.' + res, { sourceDate: source.date }), { type: 'error' });

        setSources(sources.filter(o => o.id !== source.id));
        toast(t('sources.delete.success', { sourceDate: source.date }), { type: 'success' });
      });
    }
  };


  const selectedOption = () => {
    if (!selectedSource) return null;

    console.log("243 - selectedSource=", selectedSource);

    // determine if user wants to create or edit a source
    return selectedSource.id ? (
      // <div></div>
      <Update sources={sources} setSources={setSources} selectedSource={selectedSource} setSelectedSource={setSelectedSource} />
    ) : (
      <Create sources={sources} setSources={setSources} setSelectedSource={setSelectedSource} />
    );
  };


  useEffect(() => {
    document.title = t('sources.page');

    if (sources.length === 0 && !loaded) {

      setLoaded(true);

      get({ type: 'source' }).then(res => {
        setLoading(false);

        if (!res.success)
          return toast(t('sources.errors.' + res), { type: 'error' });

        const sourceRows = [];
        for (const source of res.sources) {
          sourceRows.push({ ...source });
        }

        setSources(sourceRows);
      });
    }
  }, [t, sources, loaded]);



  // console.log("295 - apiRef.current=", apiRef.current);
  const toggleType = (type) => (
    // setTypeExpanded(typeExpanded.includes(type) ? typeExpanded.filter(s => s !== type) : [...typeExpanded, type])
    sourceDispatch({ typeExpanded: typeExpanded.includes(type) ? typeExpanded.filter(s => s !== type) : [...typeExpanded, type] })
  );


  const sortedSources = sources.sort(function (a, b) {

    // ordre de priorité loi > decret > ...
    if (a.type > b.type) {
      return 1;
    }
    if (a.type < b.type) {
      return -1;
    }

    // ORDRE ARTICLES DE LOI
    if (a.type === 10 || a.type === 20) {

      if (a.intitule > b.intitule) {
        return 1;
      }
      if (a.intitule < b.intitule) {
        return -1;
      }

      const regex = /[0-9]+/i;  // 
      const itemsA = a.article.match(regex);
      const itemsB = b.article.match(regex);

      if (parseInt(itemsA[0], 10) > parseInt(itemsB[0], 10)) {
        return 1;
      }
      if (parseInt(itemsA[0], 10) < parseInt(itemsB[0], 10)) {
        return -1;
      }

      if (a.article > b.article) {
        return 1;
      }
      if (a.article < b.article) {
        return -1;
      }

      if (a.date < b.date) {
        return 1;
      }
      if (a.date > b.date) {
        return -1;
      }

      // console.log("299 - ...");
      return 0;
    }
    //----

    return 0;
  })

  // console.log("261 - sources=", sources, loaded, sortedSources);

  /*
      "10": "Loi",
      "20": "Décret",
      "30": "Décision de justice",
      "40": "Règlement type",
      "50": "Doctrine",
      "60": "Autre"
  */
  const arrayLoi = new Array();
  const mapLoi = new Map();

  const arrayDecret = new Array();
  const mapDecret = new Map();

  const arrayJustice = new Array();
  const mapJustice = new Map();

  const arrayReglement = new Array();
  const mapReglement = new Map();

  const arrayDoctrine = new Array();
  const mapDoctrine = new Map();

  const arrayAutre = new Array();
  const mapAutre = new Map();

  for (const element of sortedSources) {
    if (element.type === 10) {
      arrayLoi.push(element);

      const key = element.intitule + "<!>" + element.article;
      let arrayTmp = mapLoi.get(key);
      if (arrayTmp === undefined) {
        arrayTmp = new Array();
      }
      arrayTmp.push(element);
      mapLoi.set(key, arrayTmp);
    }
    else if (element.type === 20) {
      arrayDecret.push(element);

      const key = element.intitule + "<!>" + element.article;
      let arrayTmp = mapDecret.get(key);
      if (arrayTmp === undefined) {
        arrayTmp = new Array();
      }
      arrayTmp.push(element);
      mapDecret.set(key, arrayTmp);
    }
    else if (element.type === 30) {
      arrayJustice.push(element);

      let key = element.juridiction + "<!>" + element.numero;
      if (element.juridiction === 20)
        key = element.juridiction + "<!>" + element.ville + "<!>" + element.numero;

      let arrayTmp = mapJustice.get(key);
      if (arrayTmp === undefined) {
        arrayTmp = new Array();
      }
      arrayTmp.push(element);
      mapJustice.set(key, arrayTmp);
    }
    else if (element.type === 40)
      arrayReglement.push(element);
    else if (element.type === 50) {
      arrayDoctrine.push(element);

      const key = element.intitule + "<!>" + element.auteur;
      let arrayTmp = mapDoctrine.get(key);
      if (arrayTmp === undefined) {
        arrayTmp = new Array();
      }
      arrayTmp.push(element);
      mapDoctrine.set(key, arrayTmp);
    }
    else if (element.type === 60) {
      arrayAutre.push(element);

      const key = element.intitule + "<!>" + element.auteur;
      let arrayTmp = mapAutre.get(key);
      if (arrayTmp === undefined) {
        arrayTmp = new Array();
      }
      arrayTmp.push(element);
      mapAutre.set(key, arrayTmp);

      arrayAutre.push(element);
    }
  }

  const sourceLen = (mapLoi.size + mapDecret.size + mapJustice.size + mapDoctrine.size + mapAutre.size);

  // console.log("338 - arrayLoi=", arrayLoi);
  // console.log("388 - mapJustice=", mapJustice);

  // console.log("338 - arrayDecret=", arrayDecret);
  // console.log("338 - arrayJustice=", arrayJustice);
  // console.log("338 - arrayReglement=", arrayReglement);
  // console.log("338 - arrayDoctrine=", arrayDoctrine);
  // console.log("338 - arrayAutre=", arrayAutre);


  // console.log("420 - expanded, typeExpanded, sourceLen=", expanded, typeExpanded, sourceLen);


  return (
    <div className="sources">
      <div className="MuiDataGrid-root" >
        {loaded && (
          <div className="content__header">
            <h2>{t('sources.title')}{'\u2000\u2013\u2000'}
              <span style={{ color: colors.dh }}>
                {loading ? (t('global.loading') + '...') : sources.length}
              </span>
            </h2>

            <div className="options">
              <Button
                className="button"
                onClick={toggleAllSources}
                startIcon={<span className="material-icons-round">
                  expand_{expanded.length === sourceLen && typeExpanded.length === 5 ? 'less' : 'more'}
                </span>}
              >{t('sources.' + (expanded.length === sourceLen && typeExpanded.length === 5 ? 'collapse' : 'expand'))}
              </Button>

              <Button
                className="button"
                onClick={() => setSelectedSource({})}
                startIcon={<span className="material-icons-round">add_circle_outline</span>}
              >{t('sources.create.button')}</Button>
            </div>
          </div>
        )}


        <div className="MuiDataGrid-main">
          {sources && sources.length > 0 ? (

            <div >
              <h2 className="container__title" onClick={() => toggleType(10)}>
                <span className="material-icons" style={{ padding: '0px 0px 0px 10px', fontSize: '200%', color: colors.dh }}>arrow_{typeExpanded.includes(10) ? 'drop_down' : 'right'}</span>
                {t('sources.types.10')}{'\u2000\u2013\u2000'}
                <span style={{ color: colors.dh }}>
                  {loading ? (t('global.loading') + '...') : mapLoi.size}
                </span>
              </h2>

              {(mapLoi.size > 0 && typeExpanded.includes(10)) && (
                <div>
                  {
                    Array.from(mapLoi.values()).map((element, key) => {
                      // console.log("element=", element, key);
                      return (
                        <SourceDetail
                          keyX={key}
                          arraySource={element}
                          // expanded={expanded}
                          // setExpanded={setExpanded}
                          handleDelete={handleDelete}
                          setSelectedSource={setSelectedSource}
                        />
                      )
                    })
                  }
                </div>
              )}

              <br />
              <h2 className="container__title" onClick={() => toggleType(20)}>
                <span className="material-icons" style={{ padding: '0px 0px 0px 10px', fontSize: '200%', color: colors.dh }}>arrow_{typeExpanded.includes(20) ? 'drop_down' : 'right'}</span>
                {t('sources.types.20')}{'\u2000\u2013\u2000'}
                <span style={{ color: colors.dh }}>
                  {loading ? (t('global.loading') + '...') : mapDecret.size}
                </span>
              </h2>

              {(mapDecret.size > 0 && typeExpanded.includes(20)) && (
                <div>
                  {
                    Array.from(mapDecret.values()).map((element, key) => {
                      // console.log("element=", element, key);
                      return (
                        <SourceDetail
                          keyX={key}
                          arraySource={element}
                          // expanded={expanded}
                          // setExpanded={setExpanded}
                          handleDelete={handleDelete}
                          setSelectedSource={setSelectedSource}
                        />
                      )
                    })
                  }
                </div>
              )}

              <br />
              <h2 className="container__title" onClick={() => toggleType(30)}>
                <span className="material-icons" style={{ padding: '0px 0px 0px 10px', fontSize: '200%', color: colors.dh }}>arrow_{typeExpanded.includes(30) ? 'drop_down' : 'right'}</span>
                {t('sources.types.30')}{'\u2000\u2013\u2000'}
                <span style={{ color: colors.dh }}>
                  {loading ? (t('global.loading') + '...') : mapJustice.size}
                </span>
              </h2>

              {(mapJustice.size > 0 && typeExpanded.includes(30)) && (
                <div>
                  {
                    Array.from(mapJustice.values()).map((element, key) => {
                      // console.log("element=", element, key);
                      return (
                        <SourceDetail
                          keyX={key}
                          arraySource={element}
                          // expanded={expanded}
                          // setExpanded={setExpanded}
                          handleDelete={handleDelete}
                          setSelectedSource={setSelectedSource}
                        />
                      )
                    })
                  }
                </div>
              )}

              {/* <br />
              <h2 className="container__title" onClick={() => toggleType(40)}>
                <span className="material-icons" style={{ padding: '0px 0px 0px 10px', fontSize: '200%', color: colors.dh }}>arrow_{typeExpanded.includes(40) ? 'drop_down' : 'right'}</span>
                {t('sources.types.40')}{'\u2000\u2013\u2000'}
                <span style={{ color: colors.dh }}>
                  {loading ? (t('global.loading') + '...') : mapReglement.size}
                </span>
              </h2>
              {(mapReglement.size > 0 && typeExpanded.includes(40)) && (
                <div>
                  {
                    Array.from(mapReglement.values()).map((element, key) => {
                      console.log("element=", element, key);
                      return (
                        <SourceDetail
                          keyX={key}
                          arraySource={element}
                          // expanded={expanded}
                          // setExpanded={setExpanded}
                          handleDelete={handleDelete}
                          setSelectedSource={setSelectedSource}
                        />
                      )
                    })
                  }
                </div>
              )} */}

              <br />
              <h2 className="container__title" onClick={() => toggleType(50)}>
                <span className="material-icons" style={{ padding: '0px 0px 0px 10px', fontSize: '200%', color: colors.dh }}>arrow_{typeExpanded.includes(50) ? 'drop_down' : 'right'}</span>
                {t('sources.types.50')}{'\u2000\u2013\u2000'}
                <span style={{ color: colors.dh }}>
                  {loading ? (t('global.loading') + '...') : mapDoctrine.size}
                </span>
              </h2>
              {(mapDoctrine.size > 0 && typeExpanded.includes(50)) && (
                <div>
                  {
                    Array.from(mapDoctrine.values()).map((element, key) => {
                      // console.log("element=", element, key);
                      return (
                        <SourceDetail
                          keyX={key}
                          arraySource={element}
                          // expanded={expanded}
                          // setExpanded={setExpanded}
                          handleDelete={handleDelete}
                          setSelectedSource={setSelectedSource}
                        />
                      )
                    })
                  }
                </div>
              )}

              <br />
              <h2 className="container__title" onClick={() => toggleType(60)}>
                <span className="material-icons" style={{ padding: '0px 0px 0px 10px', fontSize: '200%', color: colors.dh }}>arrow_{typeExpanded.includes(60) ? 'drop_down' : 'right'}</span>
                {t('sources.types.60')}{'\u2000\u2013\u2000'}
                <span style={{ color: colors.dh }}>
                  {loading ? (t('global.loading') + '...') : mapAutre.size}
                </span>
              </h2>
              {(mapAutre.size > 0 && typeExpanded.includes(60)) && (
                <div>
                  {
                    Array.from(mapAutre.values()).map((element, key) => {
                      // console.log("element=", element, key);
                      return (
                        <SourceDetail
                          keyX={key}
                          arraySource={element}
                          // expanded={expanded}
                          // setExpanded={setExpanded}
                          handleDelete={handleDelete}
                          setSelectedSource={setSelectedSource}
                        />
                      )
                    })
                  }
                </div>
              )}
            </div>

          ) : ( // Scenarios are empty or no location is selected
            <div className="loader">
              {loaded ? <h2>{t('sources.no_sources')}.</h2> : <BeatLoader color={colors.dh} />}
            </div>
          )}
        </div>
        {selectedOption()}
      </div>
    </div >
  );
}