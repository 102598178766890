import React from 'react';
import { Route, Switch } from 'react-router-dom';

// import { useAppContext } from '../Context/App/Provider';

import Header from '../Components/Dashboard/Header/Header';
import Sources from '../Components/Dashboard/Sources/List';
// import EditSource from '../Components/Dashboard/Sources/Edit';


export default function SourcePage() {
  // const [{ user }] = useAppContext();

  return (
    <>
      <Header />

      <div className="body">

        <Switch>
          <Route exact path='/sources'>
            <Sources />
          </Route>

          <Route exact path='/sources/:id'>
            {/* <EditSource /> */}
          </Route>
          
        </Switch>
      </div>
      
    </>
  );
}
