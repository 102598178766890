import React from 'react';
import copy from 'fast-copy';
import i18next from 'i18next';
import { toast } from 'react-toastify';
import { arrayMoveImmutable } from 'array-move';

import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';

import { updateSettings } from './user';



export const changeSourcesColumns = ({ view, columns, column, type, value, dispatch }) => {
    // Type can be 'hide', 'order', 'width'
    // View can be 'account', 'group', 'location'

    if (type === 'order') {
        const movedCol = Object.keys(columns).find(col => columns[col].order === value.new);
        dispatch({
            [view + 'Columns']: {
                ...columns,
                [column]: { ...columns[column], [type]: value.new },
                [movedCol]: { ...columns[movedCol], [type]: value.old }
            }
        });
    }

    else {
        dispatch({
            [view + 'Columns']: {
                ...columns,
                [column]: { ...columns[column], [type]: value }
            }
        });
    }
};


export const changeAdminColumns = ({ view, columns, column, type, value, dispatch }) => {
    // Type can be 'hide', 'order', 'width'
    // View can be 'account', 'group', 'location'

    if (type === 'order') {
        const movedCol = Object.keys(columns).find(col => columns[col].order === value.new);
        dispatch({
            [view + 'Columns']: {
                ...columns,
                [column]: { ...columns[column], [type]: value.new },
                [movedCol]: { ...columns[movedCol], [type]: value.old }
            }
        });
    }

    else {
        dispatch({
            [view + 'Columns']: {
                ...columns,
                [column]: { ...columns[column], [type]: value }
            }
        });
    }
};


export const changeSettings = (t, type, value, settings, appDispatch) => {
    if (type === 'lang') i18next.changeLanguage(value);

    const newSettings = { ...settings, [type]: value };
    appDispatch({ settings: newSettings });

    updateSettings(newSettings).then(res => !res.success && toast(t('settings.errors.' + type), { type: 'error' }));
};

export const toggleSidebar = (sidebar, dispatch) => (
    dispatch({ sidebar: !sidebar })
);


export const multiSelectStrings = (t, type, selected) => {
    let selectSomeItems;
    if (type === 'grp')
        selectSomeItems = t('global.multi_select.select_group');
    else if (type === 'loc')
        selectSomeItems = t('global.multi_select.' + (selected.groups.length > 0 ? 'select_location' : 'no_group'));
    else
        selectSomeItems = t('global.multi_select.select_some_items')

    return {
        selectSomeItems,
        search: t('global.multi_select.search'),
        selectAll: t('global.multi_select.select_all'),
        noOptions: t('global.multi_select.no_options'),
        clearSearch: t('global.multi_select.clear_search'),
        selectAllFiltered: t('global.multi_select.select_all_filtered'),
        allItemsAreSelected: t('global.multi_select.all_items_are_selected')
    }
};


export const getTimeAsZDateTime = (datetime) => {

    if (!datetime) {
        return null;
    }
    const fixedDate = new Date(datetime).valueOf() + (new Date(datetime).getTimezoneOffset() * 60 * 1000);
    return new Date(fixedDate).toJSON();
};



export const translatedGridFilters = (t) => ({
    filterOperatorActive: t('global.grid.filters.state'),
    filterOperatorValueState: t('global.grid.filters.state'),
    filterOperatorStartsWith: t('global.grid.filters.starts_with'),
    filterOperatorEndsWith: t('global.grid.filters.ends_with')
});


export const activeGridFilter = (t, GridFilterInputValue) => ({
    extendType: 'string',
    filterOperators: [
        {
            value: 'active',
            getApplyFilterFn: (filterItem) => {
                console.log('324 - filterItem=', filterItem);

                if (!filterItem.value) {
                    return null;
                }
                return ({ value }) => filterItem.value === String(value);
            },
            InputComponent: (props) => (
                <GridFilterInputValue
                    select {...props}
                    SelectProps={{ native: true }}
                >
                    <option value="" hidden disabled>{t('global.grid.filters.select')}</option>
                    <option value="true">{t('global.grid.filters.yes')}</option>
                    <option value="false">{t('global.grid.filters.no')}</option>
                </GridFilterInputValue>
            ),
        },
        // {   
        //   value: 'general_$$_location',   
        //   getApplyFilterFn: (filterItem) => {
        //     console.log('345 - filterItem=', filterItem);

        //     if (!filterItem.value) {
        //       return null;
        //     }
        //     return ({ value }) => filterItem.value === String(value);
        //   },
        //   InputComponent: (props) => (
        //     <GridFilterInputValue
        //       select {...props}
        //       SelectProps={{ native: true }}
        //     >
        //     </GridFilterInputValue>
        //   ),
        // },
    ],
});

export const setLocationGridValues = (t, loc, groups) => {
    loc.deactivation = new Date(loc.deactivation).getTime() ? new Date(loc.deactivation).toISOString().slice(0, 10) : null;
    loc.mceExpiration = new Date(loc.mceExpiration).getTime() ? new Date(loc.mceExpiration).toISOString().slice(0, 10) : null;
    loc.sdsExpiration = new Date(loc.sdsExpiration).getTime() ? new Date(loc.sdsExpiration).toISOString().slice(0, 10) : null;

    loc.dbType = loc.dbType === 1 ? 'SDS' : 'Kiosk';
    loc.group = groups.find(group => group.id === loc.groupId)?.name;

    loc.activityPc = t('global.' + (loc.activityPc ? 'yes' : 'no'));
    loc.activityMobile = t('global.' + (loc.activityMobile ? 'yes' : 'no'));

    return loc;
};