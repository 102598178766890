import copy from 'fast-copy';


export const adminReducer = (state, action) => {
  const newState = {};
  for (const key of Object.keys(action)) newState[key] = action[key];

  const finalState = copy({...state, ...newState});
  localStorage.setItem('adminState', JSON.stringify(
    finalState
    //accountColumns: null
  ));

  return finalState;
};
