import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BeatLoader from 'react-spinners/BeatLoader';
import { MultiSelect } from 'react-multi-select-component';
import { Button, TextField, InputAdornment, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import Prompt from '../../../Shared/Prompt/Prompt';
import PromptWrapper from '../../../Shared/Prompt/Wrapper';
import PromptActions from '../../../Shared/Prompt/Actions';

import { roles } from '../../../../Shared/utils';
import { create } from '../../../../Functions/admin';

import { useAppContext } from '../../../../Context/App/Provider';
import { multiSelectStrings } from '../../../../Functions/utils';


export default function CreateAccount({ users, setUsers, setSelectedUser }) {
  const { t } = useTranslation();
  const [{ user }] = useAppContext();

  const [loading, setLoading] = useState(false);
  const [newUser, setNewUser] = useState({
    role: roles.user,
    login: '',
    password: '',
    passCheck: '',
    email: ''
  });


  const handleCreateUser = (e, user) => {
    e.preventDefault();
    setLoading(true);

    // user.contactEmailActivated = user.contactEmail ? true : false;

    create({ type: 'user', user }).then(res => {
      setLoading(false);
      if (!res.success) return toast(t('admin.accounts.errors.' + res, { login: user.login }), { type: 'error' });

      const newRows = users.concat({ ...res.user, role: t('admin.accounts.types.' + res.user.role) });
      setUsers(newRows.sort((a, b) => a.login.localeCompare(b.login)));

      setSelectedUser(null);
      toast(t('admin.accounts.create.success', { login: user.login }), { type: 'success' });
    });
  };


  console.log("59 ...");
  return (
    <Prompt animated>
      <form className="prompt__container animated" onSubmit={e => handleCreateUser(e, newUser)}>
        <PromptWrapper>
          <div className="prompt__title">
            <h1>{t('admin.accounts.create.title')}</h1>
            <div>
              <select
                value={newUser.role}
                className="singleSelect"
                title={t('admin.accounts.columns.role')}
                onChange={e => {
                  const role = Number(e.target.value);                 
                  setNewUser({...newUser, role});
                }}
              >
                <option value="100">{t('admin.accounts.types.' + roles.user)}</option>
                {user.role <= roles.admin && <option value="10">{t('admin.accounts.types.' + roles.admin)}</option>}
                {user.role <= roles.superAdmin && <option value="0">{t('admin.accounts.types.' + roles.superAdmin)}</option>}
              </select>

              {/* <FormControl size="small" variant="filled" style={{ width: '100%' }}>
                <InputLabel>{t('sources.columns.type')}</InputLabel>
                <Select
                  className="singleSelect"
                  //style={{ backgroundColor: "#f0feff" }}
                  value={newUser.role}
                  label={t('admin.accounts.columns.role')}
                  onChange={e => {
                    const role = Number(e.target.value);
                    setNewUser({ ...newUser, role });
                  }}
                >
                  <MenuItem value={'100'}>{t('admin.accounts.types.' + roles.user)}</MenuItem>
                  {user.role <= roles.admin && <MenuItem value="10">{t('admin.accounts.types.' + roles.admin)}</MenuItem>}
                  {user.role <= roles.superAdmin && <MenuItem value="0">{t('admin.accounts.types.' + roles.superAdmin)}</MenuItem>}

                </Select>
              </FormControl> */}
            </div>
          </div>

          <div className="prompt__content">

            <TextField
              InputProps={{
                startAdornment: <InputAdornment position="start">
                  {/* <img style={{width: "24px"}} src="/assets/images/icons_png/account_circle.png" alt=""></img> */}
                  <span className="material-icons-outlined">account_circle</span>
                  {/* <img style={{width: "24px"}} src="/assets/images/svg/account_circle.svg" alt=""></img> */}
                </InputAdornment>
              }}
              required
              name="no-username"
              autoComplete="off"
              variant="filled"
              value={newUser.login}
              label={t('admin.accounts.columns.login')}
              onChange={e => setNewUser({ ...newUser, login: e.target.value })}
              size="small"
            />

            <TextField
              InputProps={{
                startAdornment: <InputAdornment position="start">
                  {/* <img style={{width: "24px"}} src="/assets/images/icons_png/email.png" alt=""></img> */}
                  <span className="material-icons-outlined">email</span>
                  {/* <img style={{width: '24px'}} src="/assets/images/svg/email.svg" alt=""></img> */}
                </InputAdornment>
              }}
              name="no-email"
              autoComplete="off"
              variant="filled"
              value={newUser.email}
              label={t('admin.accounts.columns.email')}
              onChange={e => setNewUser({ ...newUser, email: e.target.value })}
              size="small"
            />

            <div className="flexRow">
              <TextField
                InputProps={{
                  startAdornment: <InputAdornment position="start">
                    {/* <img style={{width: "24px"}} src="/assets/images/icons_png/lock.png" alt=""></img> */}
                    <span className="material-icons-outlined">lock</span>
                    {/* <img style={{width: '24px'}} src="/assets/images/svg/lock.svg" alt=""></img> */}
                  </InputAdornment>
                }}
                required
                type="password"
                variant="filled"
                value={newUser.password}
                label={t('reset.field1')}
                onChange={e => setNewUser({ ...newUser, password: e.target.value })}
                size="small"
              />

              <TextField
                InputProps={{
                  startAdornment: <InputAdornment position="start">
                    {/* <img style={{width: "24px"}} src="/assets/images/icons_png/lock.png" alt=""></img> */}
                    <span className="material-icons-outlined">lock</span>
                    {/* <img style={{width: '24px'}} src="/assets/images/svg/lock.svg" alt=""></img> */}
                  </InputAdornment>
                }}
                required
                type="password"
                variant="filled"
                value={newUser.passCheck}
                label={t('reset.field2')}
                onChange={e => setNewUser({ ...newUser, passCheck: e.target.value })}
                size="small"
              />
            </div>
          </div>
        </PromptWrapper>

        <PromptActions>
          <Button className="btnSecondary" onClick={() => setSelectedUser(null)}>
            {t('global.cancel')}
          </Button>

          <Button className="btnPrimary" type="submit" disabled={loading}>
            {loading ? <BeatLoader color="white" css="transform:translateY(4px)" /> : t('global.create')}
          </Button>
        </PromptActions>
      </form>
    </Prompt>
  );
}
