import Switch from 'react-switch';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';

import { Button, LinearProgress as LoadingOverlay } from '@material-ui/core';
import {
  DataGrid, useGridApiRef, GridFooter, GridFilterInputValue, frFR, enUS,
  GridToolbarContainer, GridToolbarFilterButton, GridToolbarColumnsButton
} from '@mui/x-data-grid';

import Create from './Create';
import Update from './Update';
import UpdateOwnSettings from './UpdateOwnSettings'

import { useAppContext } from '../../../../Context/App/Provider';
import { useAdminContext } from '../../../../Context/Admin/Provider';

import { get, update, remove, logInAs } from '../../../../Functions/admin';

import { colors, roles, useStateWithCallback } from '../../../../Shared/utils';
import { changeAdminColumns, changeSettings, activeGridFilter, translatedGridFilters } from '../../../../Functions/utils';

// import { logout } from '../../../../Functions/auth';

// import { ReactComponent as ico_edit } from '/assets/images/svg/edit.svg';
// import { ReactComponent as ico_delete } from '/assets/images/svg/delete.svg';
// import { ReactComponent as ico_add_circle_outline } from '/assets/images/svg/add_circle_outline.svg';



import '../Admin.css';


export default function AccountsList() {

  const history = useHistory();
  const { t } = useTranslation();
  // const apiRef = useGridApiRef();

  const [{ settings, user }, appDispatch] = useAppContext();
  const [{ accountColumns }, dispatch] = useAdminContext();


  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [model, setModel] = useStateWithCallback({ filtered: null, selected: [] });

  if (accountColumns && accountColumns.login === undefined) {
    localStorage.removeItem("adminState");
    // appDispatch({ forceReload: true });
  }

  const columns = [ // default visible width: 1500
    { field: 'id', headerName: 'ID', hide: accountColumns.id.hide, filterable: false, width: accountColumns.id.width },
    {
      sortable: false,
      type: 'activeFilter',
      field: 'isActive',
      hide: accountColumns.isActive.hide,
      width: accountColumns.isActive.width,
      headerName: t('admin.accounts.columns.active'),

      renderCell: ({ row }) => {
        const icon = (
          <svg viewBox="0 0 10 10" height="100%" width="100%" fill="#fff">
            <circle r={3.5} cx={5} cy={5} />
          </svg>
        );

        return (
          <Switch
            checked={row.isActive}
            onChange={() => toggleActivated(row)}
            disabled={loading || user.id === row.id}
            offHandleColor="#888" onHandleColor="#080"

            checkedIcon={null} uncheckedIcon={null}
            checkedHandleIcon={icon} uncheckedHandleIcon={icon}
          />
        );
      }
    },
    {
      field: 'login',
      hide: accountColumns.login.hide,
      width: accountColumns.login.width,
      headerName: t('admin.accounts.columns.login')
    },
    {
      field: 'email',
      hide: accountColumns.email.hide,
      width: accountColumns.email.width,
      headerName: t('admin.accounts.columns.email')
    },
    {
      field: 'latestConnection',
      hide: accountColumns.latestConnection.hide,
      width: accountColumns.latestConnection.width,
      headerName: t('admin.accounts.columns.last'),
      align: 'left',
      headerAlign: 'left',

      renderCell: ({ row }) => {
        if (row && row.latestConnection) {
          const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: '2-digit'
          };

          const lastTxt = new Date(row.latestConnection).toLocaleDateString(settings.lang ? settings.lang : 'en', options);
          return (
            <div>
              {lastTxt}
            </div>
          )
        }
        return (
          <div>
            ...
          </div>
        )
      }
    },

    {
      field: 'role',
      hide: accountColumns.role.hide,
      width: accountColumns.role.width,
      headerName: t('admin.accounts.columns.role'),

      renderCell: ({ row }) => {
        return (
          <div>{t('admin.accounts.types.' + row.role)}</div>
        )
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      hide: accountColumns.actions.hide,
      width: accountColumns.actions.width,

      sortable: false,
      filterable: false,

      align: 'right',
      headerAlign: 'right',

      renderCell: ({ row }) => {

        return (
          <div>
            <Button
              disabled={loading}
              title={t('global.update')}
              style={{ minWidth: 'unset' }} // Redirect to settings for self changes
              onClick={() => {
                setSelectedUser(row)
                // if (user.id !== row.id) setSelectedUser(row)
                // else if (window.confirm(t('admin.accounts.update.redirect'))) history.push('/settings');
              }}
            >
              {/* <img style={{ color: '#222', width: "24px" }} src="/assets/images/icons_png/edit.png" alt=""></img> */}
              <span className="material-icons-outlined" style={{ color: '#222' }}>edit</span>
              {/* <img style={{width: '24px', color: '#222'}} src="/assets/images/svg/edit.svg" alt=""></img> */}
            </Button>

            {user.role <= roles.superAdmin ?
              (
                <Button
                  title={t('global.delete')}
                  style={{ minWidth: 'unset' }}
                  onClick={() => handleDelete(row)}
                  disabled={loading || user.id === row.id}
                >
                  {/* <img style={{ color: '#f00', width: "24px" }} src="/assets/images/icons_png/delete.png" alt=""></img> */}
                  <span className="material-icons-outlined" style={{ color: '#f22' }}>delete</span>
                  {/* <img style={{width: '24px', color: '#F22'}} src="/assets/images/svg/delete.svg" alt=""></img> */}
                </Button>
              ) : (<span></span>)
            }

            {/* {user.role <= roles.superAdmin ?
              (
                <Button
                  title={t('admin.accounts.log_in_as.login')}
                  style={{ minWidth: 'unset' }}
                  onClick={() => handleLogInAs(row)}
                  disabled={loading || user.id === row.id}
                >
                  <span className="material-icons-outlined">login</span>
                </Button>
              ) : (<span></span>)
            } */}
          </div>
        )
      }
    }
  ] // Keep columns order between sessions
    .sort((a, b) => accountColumns[a.field].order - accountColumns[b.field].order);


  const toggleActivated = (user) => {
    setLoading(true);
    user.isActive = !user.isActive;

    // locations: null -> Skip adding / removing locations on account update
    update({ type: 'user', user: { ...user } }).then(res => {
      setLoading(false);
      if (!res.success) {
        user.isActive = !user.isActive;
        return toast(t('admin.accounts.errors.' + res, { login: user.login }), { type: 'error' });
      }

      toast(t('admin.accounts.' + (res.user.isActive ? 'activated' : 'deactivated'), { login: user.login }), { type: 'success' });
    });
  };

  const handleDelete = (user) => {
    if (window.confirm(t('admin.accounts.delete.confirm', { login: user.login }))) {
      setLoading(true);

      remove({ type: 'user', id: user.id }).then(res => {
        setLoading(false);
        if (!res.success) return toast(t('admin.accounts.errors.' + res, { login: user.login }), { type: 'error' });

        setUsers(users.filter(row => row.id !== user.id));
        toast(t('admin.accounts.delete.success', { login: user.login }), { type: 'success' });
      });
    }
  };

  const handleLogInAs = (user) => {
    // if (window.confirm(t('admin.accounts.log_in_as.confirm', { login: user.login }))) {
    //   setLoading(true);

    //   logInAs({ id: user.id }).then(res => {
    //     setLoading(false);
    //     if (!res.success) logout().then(() => appDispatch({ user: {} }));

    //     else {

    //       // Reset local storage when logging in with a different user
    //       if (Number(localStorage.getItem('id')) !== res.user.id) {
    //         // const redirect = sessionStorage.getItem('redirect');

    //         localStorage.clear();
    //         sessionStorage.clear();
    //         sessionStorage.setItem('redirect', '/reporting');
    //       }
    //       localStorage.setItem('id', res.user.id);
    //       localStorage.setItem('signedIn', res.success);

    //       // Refresh the whole website state using window.location
    //       window.location.pathname = sessionStorage.getItem('redirect') ?? '';

    //       // setUsers(users.filter(row => row.id !== user.id));
    //       // toast(t('admin.accounts.log_in_as.success', { email: user.email }), { type: 'success' });
    //       // setLoading(false);
    //       // console.log("223 - DONE...", window.location.pathname);
    //     }
    //   });

    // }
  };

  const selectedOption = () => {
    if (!selectedUser) return null;

    console.log("selectedUser=", selectedUser);
    console.log("user=", user);

    //UpdateOwnSettings

    // determine if user wants to create or edit an account
    return selectedUser.id ? (user.id === selectedUser.id ? (
      <UpdateOwnSettings users={users} setUsers={setUsers} selectedUser={selectedUser} setSelectedUser={setSelectedUser} />
    ) : (
      <Update users={users} setUsers={setUsers} selectedUser={selectedUser} setSelectedUser={setSelectedUser} />
    )
    ) : (
      <Create users={users} setUsers={setUsers} setSelectedUser={setSelectedUser} />
    );
  };


  useEffect(() => {
    document.title = t('admin.accounts.page');

    if (users.length === 0) {
      get({ type: 'user' }).then(res => {
        setLoading(false);

        if (!res.success)
          return toast(t('admin.accounts.errors.' + res), { type: 'error' });

        const userRows = [];
        for (const user of res.users) {
          userRows.push({ ...user });
        }

        setUsers(userRows);
      });
    }
  }, [t, users]);


  // console.log("273 - users=", users);
  // console.log("295 - apiRef.current=", apiRef.current);

  return (
    // <div>....</div>

    <div className="admin accounts">
      <DataGrid
        // apiRef={apiRef}
        loading={loading}

        // checkboxSelection
        disableSelectionOnClick

        pageSize={settings.pageSize}
        rows={users}
        columns={columns}
        pagination={settings.pagination}

        onSelectionModelChange={selected => setModel({ ...model, selected })}
        columnTypes={{ activeFilter: activeGridFilter(t, GridFilterInputValue) }}
        onPageSizeChange={pageSize => changeSettings(t, 'pageSize', pageSize, settings, appDispatch)}

        onColumnWidthChange={col => changeAdminColumns({
          column: col.colDef.field, columns: accountColumns, dispatch,
          view: 'account', type: 'width', value: col.width
        })} // Save custom column width set by the user
        onColumnVisibilityChange={col => changeAdminColumns({
          column: col.field, columns: accountColumns, dispatch,
          view: 'account', type: 'hide', value: !col.isVisible
        })} // Save hidden column state set by the user
        onColumnOrderChange={col => changeAdminColumns({
          column: col.field, columns: accountColumns, dispatch,
          view: 'account', type: 'order', value: { old: col.oldIndex, new: col.targetIndex }
        })} // Save custom column order set by the user

        localeText={{
          ...(localStorage.getItem('lang') === 'fr' ? frFR : enUS).props.MuiDataGrid.localeText,
          ...translatedGridFilters(t)
        }}

        components={{
          LoadingOverlay,

          Toolbar: () => (
            <div className="content__header">
              <h2>{t('admin.accounts.title')}{'\u2000\u2013\u2000'}
                <span style={{ color: colors.dh }}>
                  {loading ? (t('global.loading') + '...') : users.length}
                </span>
              </h2>

              <div className="options">
                <Button
                  className="button"
                  onClick={() => setSelectedUser({})}
                  startIcon={
                    // <img style={{ width: "24px" }} src="/assets/images/icons_png/add_circle_outline.png" alt=""></img>
                    <span className="material-icons">add_circle_outline</span>
                    // <img style={{width: '44px', fill:'red', stroke:'green'}} src="/assets/images/svg/add_circle_outline.svg" alt=""></img>
                    // <ico_edit />
                  }
                >{t('admin.accounts.create.button')}</Button>
              </div>
            </div>
          ),

          Footer: () => (
            <div className="datagrid__footer">
              <GridFooter />
              <div className="checkbox">
                <input
                  id="pagination"
                  type="checkbox"
                  defaultChecked={settings.pagination}
                  onChange={() => changeSettings(t, 'pagination', !settings.pagination, settings, appDispatch)}
                />
                <label htmlFor="pagination">PAGINATION</label>
              </div>
            </div>
          )
        }}
      />
      {selectedOption()}
    </div>
  );
}
