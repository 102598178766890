import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BeatLoader from 'react-spinners/BeatLoader';
import React, { useEffect, useState } from 'react';
import { Button, TextField, InputAdornment, Grid } from '@material-ui/core';

import Prompt from '../Shared/Prompt/Prompt';
import PromptWrapper from '../Shared/Prompt/Wrapper';
import PromptActions from '../Shared/Prompt/Actions';

import { roles } from '../../Shared/utils';
import { login, logout } from '../../Functions/auth';

import './Auth.css';


export default function Login() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [supported, setSupported] = useState(null);

  const [remember, setRemember] = useState(true);
  const [userLogin, setUserLogin] = useState({
    login: null,
    password: null
  });



  // TMP TMP TMP TMP TMP TMP TMP TMP TMP TMP TMP TMP TMP TMP TMP TMP TMP TMP 
  // DEBUG
  // const getRandomId = () => {
  //   let pass = '';
  //   const str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' +
  //     'abcdefghijklmnopqrstuvwxyz' +
  //     '0123456789' +
  //     '!@#$&+=';

  //   for (let i = 1; i <= 18; i++) {
  //     let char = Math.floor(Math.random() * str.length + 1);

  //     while (pass.indexOf(str.charAt(char)) !== -1) {
  //       console.log("690 - renew", str.charAt(char));
  //       char = Math.floor(Math.random() * str.length + 1);
  //     }
  //     pass += str.charAt(char)
  //   }
  //   return pass;
  // }
  // DEBUG
  // TMP TMP TMP TMP TMP TMP TMP TMP TMP TMP TMP TMP TMP TMP TMP TMP TMP TMP 



  const handleLogin = async (e) => {
    e.preventDefault();

    if (!userLogin.login || !userLogin.password)
      return toast(t('login.errors.incomplete'), { type: 'error' });

    setLoading(true);
    const res = await login(userLogin, remember);
    if (!res.success) {
      setLoading(false);
      return toast(t('login.errors.' + res), { type: 'error' });
    }

    setLoading(false);

    // Reset local storage when logging in with a different user
    if (Number(localStorage.getItem('id')) !== res.user.id) {
      const redirect = sessionStorage.getItem('redirect');

      localStorage.clear();
      sessionStorage.clear();
      sessionStorage.setItem('redirect', redirect);
    }

    localStorage.setItem('id', res.user.id);
    localStorage.setItem('signedIn', res.success);

    // Refresh the whole website state using window.location
    window.location.pathname = sessionStorage.getItem('redirect') ?? '';
  };


  useEffect(() => {
    document.title = t('login.page');

    import('detect-browser').then(({ detect }) => (
      setSupported(!['edge', 'ie'].includes(detect().name))
    ));
  }, [t]);


  return (
    <Prompt>
      <form className="prompt__container" style={{ width: "30%", maxWidth: "9999px", height: '66%' }} onSubmit={handleLogin}>
        {supported && <>
          <PromptWrapper>
            <div className="prompt__title">
              <h1>
                <img src="/assets/images/icons8-login-67.png" alt="Logo" />
              </h1>              

              <h1>
                {t('login.title')}
              </h1>
            </div>

            <span className="prompt__content">
              <TextField
                InputProps={{
                  startAdornment: <InputAdornment position="start">
                    {/* <img style={{width: "24px"}} src="/assets/images/icons_png/account_circle.png" alt=""></img> */}
                    {/* <span className="material-icons-outlined">account_circle</span> */}
                    <img style={{width: '24px'}} src="/assets/images/svg/account_circle.svg" alt=""></img>
                  </InputAdornment>
                }}
                name="username"
                variant="filled"
                label={t('login.field1')}
                onChange={e => setUserLogin({ ...userLogin, login: e.target.value })}
              />

              &nbsp;

              <TextField
                InputProps={{
                  startAdornment: <InputAdornment position="start">
                    {/* <img style={{width: "24px"}} src="/assets/images/icons_png/lock.png" alt=""></img> */}
                    {/* <span className="material-icons-outlined">lock</span> */}
                    <img style={{width: '24px'}} src="/assets/images/svg/lock.svg" alt=""></img>
                  </InputAdornment>
                }}
                name="password"
                type="password"
                variant="filled"
                label={t('login.field2')}
                onChange={e => setUserLogin({ ...userLogin, password: e.target.value })}
              />
              <Link to="/forgot" className="action">{t('login.forgot')}</Link>
            </span>

          </PromptWrapper>

          <PromptActions column>
            <Button className="btnPrimary" type="submit" disabled={loading}>
              {loading ? <BeatLoader color="white" css="transform:translateY(4px)" /> : t('login.button')}
            </Button>
          </PromptActions>
        </>}

        {supported === false && (
          <div className="prompt__wrapper">
            <div className="prompt__title">
              <h1>{t('browser.unsupported.title')}</h1>
            </div>

            <div className="prompt__content">
              <p>{t('browser.unsupported.description')}</p>
              <p style={{ marginTop: '30px' }}>{t('browser.unsupported.download')}</p>
              <div className="browsers">
                <a href="https://microsoft.com/edge" aria-label={t('browser.new_edge')}>
                  <img src="/assets/images/edge.png" alt="edge" width="100px" height="100px" />
                </a>
                <a href="https://google.com/chrome" aria-label={t('browser.chrome')}>
                  <img src="/assets/images/chrome.png" alt="chrome" width="100px" height="100px" />
                </a>
                <a href="https://mozilla.org/firefox" aria-label={t('browser.firefox')}>
                  <img src="/assets/images/firefox.png" alt="firefox" width="100px" height="100px" />
                </a>
              </div>
            </div>
          </div>
        )}
      </form>
    </Prompt>
  );
}
