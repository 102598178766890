import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../Context/App/Provider';

import './logoBar.css';

const versionData = require('../../version.json')

export default function Header() {
  const { t } = useTranslation();
  const [{ version }, appDispatch] = useAppContext();

  if (!version) {
    appDispatch({ version: versionData });
  }

  return (
    <div>
      <div
        style={{
          backgroundImage: "url(" + '/assets/images/Grand_Place_vue1_photoshop_2.jpg' + ")",
          position: "absolute",
          zIndex: "-1",
          height: "100%",
          width: "100%",
          display: "block",
          top: "0",
          left: "0",
          backgroundSize: "cover",
          backgroundPosition: "center center"
        }}
      />

      <div className={'logobar__bg'}></div>

      <img
        style={{
          position: "absolute",
          top: "0px",
          left: "0px",
          minHeight: "var(--header-height)",
          maxWidth: "33vw",
          width: "var(--logobar-width)",
          padding: "10px 10px 10px 10px"
        }}
        src="/assets/images/logo_coprogeom.png" alt="Logo"
      />

      <img
        style={{
          position: "absolute",
          top: "40%",
          left: "0px",
          minHeight: "var(--header-height)",
          maxWidth: "33vw",
          width: "var(--logobar-width)",
          padding: "10px 10px 10px 10px"
        }}
        src="/assets/images/logo_dh.png" alt="Logo"
      />

      <img
        style={{
          position: "absolute",
          bottom: "0px",
          left: "0px",
          minHeight: "var(--header-height)",
          maxWidth: "33vw",
          width: "var(--logobar-width)",
          padding: "10px 10px 10px 10px"
        }}
        src="/assets/images/logo_cnam.png" alt="Logo"
      />

      <span
        style={{
          display: "grid", fontSize: "14px",
          position: "absolute",
          bottom: "0%",
          left: "50%",
          marginTop: "0px",
          marginLeft: "-100px",
          width: "400px",
          height: "30px",
          verticalAlign: "bottom"
        }}
      >
        {"© 2023 - Tous droits réservés."}
      </span>
    </div>

  );
}



// {/* <span>
//           {/* {(version && version.version) && (
//             <span style={{ display: "grid", fontSize: "12px" }}>
//               {t('global.version_letter')}
//               {version.version}
//             </span>
//           )} */}
//           </span > * /}
