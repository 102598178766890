import React from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";

import "./Editor.css";

export const Editor = () => {
  const [state, setState] = React.useState({ value: null });

  const handleChange = value => {
    setState({ value });
  };


  return (
    <div
      style={{
        margin: '10px',
        //paddingBottom: '14px',
        width: '100%',
        maxWidth: "100%",
        
        // height: "calc(90% - 56px)",
        // maxHeight: "calc(90% - 56px)",
        // height: "calc(100% - 128px)",
        // maxHeight: "calc(100% - 128px)",
        // height: "calc(100% -  164px)",
        // maxHeight: "calc(100% - 164px)",
        // display: 'unset',

        height: "calc(100% - 56px)",
        minHeight: "calc(100% - 56px)",
        maxHeight: "calc(100% - 56px)",

        
      }}
    >

      <EditorToolbar />

      <ReactQuill
        style={{ height: "100%", maxHeight: "100%" }}
        theme="snow"
        value={state.value}
        onChange={handleChange}
        // placeholder={"Write something awesome..."}
        modules={modules}
        formats={formats}
        bounds={'#root'}
      />
    </div >
  );
};

export default Editor;