import axios from 'axios';

import { apiUrl, config } from '../Shared/config';


export const getUserData = async () => {
  try {
    const {data} = await axios.get(apiUrl + 'user/data', config);

    return data.success ? data : data?.error;

  } catch (error) { localStorage.removeItem('signedIn'); return error; }
};


export const checkUserToken = async () => {
  try {
    const {data} = await axios.get(apiUrl + 'user/check', config);

    return data.success ? data : data?.error;

  } catch (error) { localStorage.removeItem('signedIn'); return error; }
};

export const updateSettings = async (settings) => {
  try {
    const {data} = await axios.put(apiUrl + 'user/settings', {settings}, config);

    return data.success ? data : data?.error;

  } catch (error) { return error.response?.data?.error || 'error'; }
};

export const updateSavedGroups = async (groups) => {
  try {
    const {data} = await axios.put(apiUrl + 'user/groups', {groups}, config);

    return data.success ? data : data?.error;

  } catch (error) { return error.response?.data?.error || 'error'; }
};

export const updateSavedLocations = async (locations) => {
  try {
    const {data} = await axios.put(apiUrl + 'user/locations', {locations}, config);

    return data.success ? data : data?.error;

  } catch (error) { return error.response?.data?.error || 'error'; }
};

export const updateSavedScenario = async (scenario) => {
  try {
    const {data} = await axios.put(apiUrl + 'user/scenario', {scenario}, config);

    return data.success ? data : data?.error;

  } catch (error) { return error.response?.data?.error || 'error'; }
};

export const updateScenariosOrder = async (scenariosList) => {
  try {
    const {data} = await axios.put(apiUrl + 'user/scenariosOrder', {scenariosList}, config);

    return data.success ? data : data?.error;

  } catch (error) { return error.response?.data?.error || 'error'; }
};


export const changePw = async (passwords) => {
  try {
    const {data} = await axios.post(apiUrl + 'user/change', passwords, config);

    return data.success ? data : data?.error;
    
  } catch (error) { return error.response?.data?.error || 'error'; }
};


export const changeUsername = async (username) => {
  try {
    const {data} = await axios.put(apiUrl + 'user/name', {username}, config);

    return data.success ? data : data?.error;
    
  } catch (error) { return error.response?.data?.error || 'error'; }
};


export const setContactEmail = async (id, contactEmail, lang) => {
  try {
    const {data} = await axios.put(apiUrl + 'user/email', {id, contactEmail, lang}, config);

    return data.success ? data : data?.error;

  } catch (error) { return error.response?.data?.error || 'error'; }
};


export const activateContactEmail = async (id, contactEmail, contactEmailCode) => {
  try {
    contactEmailCode = contactEmailCode.replace(' ', '')
    const {data} = await axios.put(apiUrl + 'user/email', {id, contactEmail, contactEmailCode}, config);

    return data.success ? data : data?.error;

  } catch (error) { return error.response?.data?.error || 'error'; }
};
