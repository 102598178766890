import React from 'react';

import Login from '../../Components/Auth/Login';
import Header from '../../Components/Auth/Header';


export default function LoginPage() {

  return (
    <div className="auth">
      <Header/>
      <Login/>
    </div>
  );
}
