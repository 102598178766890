import React, { createContext, useContext, useReducer } from 'react';


const appState = {
  user: null,
  sidebar: true,
  settings: null,
  version: null,

  loading: false
};

const AppContext = createContext();
export const AppProvider = ({ reducer, children }) => (
  <AppContext.Provider value={useReducer(reducer, appState)}>
    {children}
  </AppContext.Provider>
);

export const useAppContext = () => useContext(AppContext);
