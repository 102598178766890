import { useEffect, useRef, useState } from 'react';


export const values = {
  defaultSettings: {    
    pageSize: 50,
    pagination: false,
    lang: localStorage.getItem('lang') || 'fr'
  },

  languages: [
    {
      code: 'en',
      name: 'English'
    },
    {
      code: 'fr',
      name: 'Français'
    },
  ],

  maxReportingPrint: 0 // Change number to 0 to allow an infinite amount of printable columns
};


export const colors = {
  dh: '#03329b',
  success: '#22b222',
  warning: '#ffa600',
  error: '#cc6633'
};


export const roles = {
  superAdmin: 0,  
  admin: 10,
  user: 100
};


export const typeDonnees = {
  loi: 10,  
  decret: 20,
  justice: 30,
  reglementType: 40,
  doctrine: 50,
  autre: 60
};


export const formatReportingHeader = (t, category, column, parentTitle = null) => {
  if (parentTitle) {
    // console.log("49 - txt=", `${parentTitle} \u2013 ${t(`reporting.columns.${category}.sub_title`)} \u2013 ${t(`reporting.columns.${category}.${column}`)}`);
    return (
      `${t(parentTitle)} \u2013 ${t(`reporting.columns.${category}.sub_title`)} \u2013 ${t(`reporting.columns.${category}.${column}`)}`
    );
  }
  else {
    // console.log("55 - txt=", `${t(`reporting.columns.${category}.sub_title`)} \u2013 ${t(`reporting.columns.${category}.${column}`)}`);
    return (
      `${t(`reporting.columns.${category}.sub_title`)} \u2013 ${t(`reporting.columns.${category}.${column}`)}`
    );
  }
};


export const handlePromptScrollShadow = (initialCheck) => {
  const prompt = document.querySelector('.prompt__wrapper');
  if (!prompt) return;

  const actions = document.querySelector('.prompt__actions');
  // Adding 1 is necessary because display scaling can give decmial undervalues.
  const isBottom = (prompt.scrollTop + 1) >= prompt.scrollHeight - prompt.clientHeight;

  if (initialCheck !== true) actions.style.transition = 'box-shadow 0.25s';
  actions.style.boxShadow = isBottom ? 'none' : '';
};


export const getRole = (role) => {
  const roles = {
    0: 'Super Administrator',
    10: 'Administrator',
    100: 'User'
  };
  return roles[role];
};


export const useStateWithCallback = (initialState) => {
  const [state, setState] = useState(initialState);
  const callbackRef = useRef(() => undefined);

  const setStateCB = (newState, callback = () => null) => {
    callbackRef.current = callback;
    setState(newState);
  };

  useEffect(() => { callbackRef.current?.(); }, [state]);
  return [state, setStateCB];
};

