import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BeatLoader from 'react-spinners/BeatLoader';
import { MultiSelect } from 'react-multi-select-component';

import { Button, TextField, InputAdornment, Select, MenuItem, FormControl, InputLabel, Checkbox, FormControlLabel } from '@material-ui/core';


import InputMask from "react-input-mask";


import Prompt from '../../Shared/Prompt/Prompt';
import PromptWrapper from '../../Shared/Prompt/Wrapper';
import PromptActions from '../../Shared/Prompt/Actions';

import { roles, typeDonnees } from '../../../Shared/utils';
import { create } from '../../../Functions/admin';

import { useAppContext } from '../../../Context/App/Provider';
import { multiSelectStrings } from '../../../Functions/utils';



function leapYear(year) {
  return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
}

function validateDayMonth(day, month, year) {

  console.log("32 - day=", day);
  console.log("33 - month=", month);
  console.log("34 - year=", year);

  var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
  var days = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"];
  if (months.indexOf(month) != -1 && days.indexOf(day) != -1) {
    if ((month == '02' && day == '29' && leapYear(year) == false) || (month == '02' && day == '30') || (month == '02' && day == '31') || (month == '04' && day == '31') || (month == '06' && day == '31') || (month == '09' && day == '31') || (month == '11' && day == '31')) {
      return false;
    } else {
      var GivenDate = year + '-' + month + '-' + day;
      var CurrentDate = new Date();
      GivenDate = new Date(GivenDate);
      if (GivenDate > CurrentDate) {
        return false;
      } else {
        return true;
      }
    }
  } else {
    return false;
  }
}


export default function CreateSource({ sources, setSources, setSelectedSource }) {
  const { t } = useTranslation();
  const [{ user }] = useAppContext();

  const defaultIntituleLoi = "65-557 du 10 juillet 1965 fixant le statut de la copropriété des immeubles bâtis";

  const [loading, setLoading] = useState(false);
  const [newSource, setNewSource] = useState({
    type: typeDonnees.loi,
    intitule: '',
    juridiction: '',
    ville: '',
    date: '',
    article: '',
    auteur: '',
    numero: '',
    ordrePublic: true,
    texte: '',
    mots_supp: '',
    commentaires: '',
    lien: '',
    cass_rejet: '',
    isHidden: false
  });

  const [errors, setErrors] = useState({});

  const validateSource = (source) => {

    // validateDayMonth
    // date dd / mm / yyyy
    const dateD = source.date.substring(0, 2);
    const dateM = source.date.substring(5, 7);
    const dateY = source.date.substring(10, 14);

    const errorsTmp = {
      date: (validateDayMonth(dateD, dateM, dateY) ? null : "error")
    }
    setErrors(
      errorsTmp
    )
    return (!!!errorsTmp.date);
  }

  const handleCreateSource = (e, source) => {
    e.preventDefault();
    if (!validateSource(source)) {
      console.log("101 - Error");
      return;
    }

    setLoading(true);

    if (source.type === 10 || source.type === 20) {
      source.juridiction = '';
      source.ville = '';
      source.numero = '';
      source.auteur = '';
      source.lien = '';
      source.cass_rejet = '';
    }

    if (source.type === 30) {
      source.intitule = '';
      source.article = '';
      source.auteur = '';
      source.ordrePublic = '';

      if (source.juridiction === 10)
        source.ville = '';

      if (source.juridiction === 20)
        source.cass_rejet = '';
    }

    if (source.type === 50 || source.type === 60) {
      source.article = '';
      source.numero = '';
      source.juridiction = '';
      source.ville = '';
      source.ordrePublic = '';
      source.cass_rejet = '';
    }

    if (isLoi && newSource.intitule === "") {
      source.intitule = defaultIntituleLoi;
    }

    create({ type: 'source', source }).then(res => {
      setLoading(false);
      if (!res.success)
        return toast(t('sources.errors.' + res, { source: source.intitule }), { type: 'error' });

      const newSources = sources.concat({ ...res.source });
      setSources(newSources);

      setSelectedSource(null);
      toast(t('sources.create.success', { login: user.login }), { type: 'success' });
    });
  };



  let formatChars = {
    'Y': '[0-9]',
    'd': '[0-3]',
    'D': '[0-9]',
    'm': '[0-1]',
    'M': '[1-9]'
  };


  const beforeDateMaskedValueChange = (newState, oldState, userInput) => {
    let { value } = newState;

    let dateParts = value.split(' / ');
    let dayPart = dateParts[0];
    let monthPart = dateParts[1];

    if (dayPart) {

      // Conditional mask for the 2nd digit of day based on the first digit
      if (dayPart.startsWith('3'))
        formatChars['D'] = '[0-1]'; // To block 39, 32, etc.
      else if (dayPart.startsWith('0'))
        formatChars['D'] = '[1-9]'; // To block 00.
      else
        formatChars['D'] = '[0-9]'; // To allow 05, 15, 25  etc.

    }
    if (monthPart) {
      // Conditional mask for the 2nd digit of month based on the first digit
      if (monthPart.startsWith('1'))
        formatChars['M'] = '[0-2]'; // To block 15, 16, etc.
      else
        formatChars['M'] = '[1-9]'; // To allow 05, 06  etc - but blocking 00.
    }
    return { value, selection: newState.selection };
  }


  console.log("59 - newSource=", newSource, errors);

  const isLoi = newSource.type === 10;
  const isDecret = newSource.type === 20;
  const isReglementType = (newSource.type === 40);
  const isDoctrine = newSource.type === 50;
  const isAutre = newSource.type === 60;
  const isJustice = (newSource.type === 30);
  const isCassation = (isJustice && newSource.juridiction === 10);
  const isCourAppel = (isJustice && newSource.juridiction === 20);
  
  return (
    <Prompt animated>
      <form className="prompt__container animated" style={{ maxHeight: "80%", maxWidth: "9999px" }} onSubmit={e => handleCreateSource(e, newSource)}>
        <PromptWrapper>
          <div className="prompt__title">
            <h1>{t('sources.create.title')}</h1>
          </div>

          <div className="prompt__content">

            <div className="flexRow">
              <div style={{ width: '30%' }}>
                <FormControl size="small" variant="filled" style={{ width: '100%' }}>
                  <InputLabel>{t('sources.columns.type')}</InputLabel>
                  <Select
                    //style={{ backgroundColor: "#f0feff" }}
                    value={newSource.type}
                    label={t('sources.columns.type')}
                    onChange={e => {
                      const type = Number(e.target.value);
                      setNewSource({ ...newSource, type });
                    }}
                  >
                    <MenuItem value={'10'}>{t('sources.types.' + typeDonnees.loi)}</MenuItem>
                    <MenuItem value={'20'}>{t('sources.types.' + typeDonnees.decret)}</MenuItem>
                    <MenuItem value={'30'}>{t('sources.types.' + typeDonnees.justice)}</MenuItem>
                    {/* <MenuItem value={'40'}>{t('sources.types.' + typeDonnees.reglementType)}</MenuItem> */}
                    <MenuItem value={'50'}>{t('sources.types.' + typeDonnees.doctrine)}</MenuItem>
                    <MenuItem value={'60'}>{t('sources.types.' + typeDonnees.autre)}</MenuItem>

                  </Select>
                </FormControl>
              </div>

              {(isLoi || isDecret || isDoctrine || isAutre) && (
                <div style={{ width: '70%' }}>
                  <TextField
                    required={isLoi || isDecret || isDoctrine}
                    variant="filled"
                    style={{ width: '100%' }}
                    value={ (isLoi && newSource.intitule === "") ? defaultIntituleLoi : newSource.intitule}
                    label={t('sources.columns.intitule')}
                    onChange={e => setNewSource({ ...newSource, intitule: e.target.value })}
                    size="small"                    
                  />
                </div>
              )}

              {(isJustice) && (
                <>
                  <div style={{ width: (isCourAppel ? '30%' : '70%') }}>
                    <FormControl size="small" variant="filled" style={{ width: '100%' }}>
                      <InputLabel>{t('sources.columns.juridiction')}</InputLabel>
                      <Select
                        value={newSource.juridiction}
                        label={t('sources.columns.juridiction')}
                        onChange={e => {
                          const juridiction = Number(e.target.value);
                          setNewSource({ ...newSource, juridiction });
                        }}
                      >
                        <MenuItem value={'10'}>{t('sources.juridiction.10')}</MenuItem>
                        <MenuItem value={'20'}>{t('sources.juridiction.20')}</MenuItem>

                      </Select>
                    </FormControl>
                  </div>

                  {(isCourAppel) && (
                    <div style={{ width: '40%' }}>

                      <FormControl size="small" variant="filled" style={{ width: '100%' }}>
                        <InputLabel>{t('sources.columns.ville')}</InputLabel>
                        <Select
                          required
                          value={newSource.ville}
                          label={t('sources.columns.ville')}
                          onChange={e => {
                            const ville = e.target.value;
                            setNewSource({ ...newSource, ville });
                          }}
                        >

                          <MenuItem value={'Agen'}>{'Agen'}</MenuItem>
                          <MenuItem value={'Aix'}>{'Aix-en-Provence'}</MenuItem>
                          <MenuItem value={'Amiens'}>{'Amiens'}</MenuItem>
                          <MenuItem value={'Angers'}>{'Angers'}</MenuItem>
                          <MenuItem value={'BasseTerre'}>{'Basse-Terre'}</MenuItem>
                          <MenuItem value={'Bastia'}>{'Bastia'}</MenuItem>
                          <MenuItem value={'Besancon'}>{'Besançon'}</MenuItem>
                          <MenuItem value={'Bordeaux'}>{'Bordeaux'}</MenuItem>
                          <MenuItem value={'Bourges'}>{'Bourges'}</MenuItem>
                          <MenuItem value={'Caen'}>{'Caen'}</MenuItem>
                          <MenuItem value={'Cayenne'}>{'Cayenne'}</MenuItem>
                          <MenuItem value={'Chambery'}>{'Chambéry'}</MenuItem>
                          <MenuItem value={'Colmar'}>{'Colmar'}</MenuItem>
                          <MenuItem value={'Dijon'}>{'Dijon'}</MenuItem>
                          <MenuItem value={'Douai'}>{'Douai'}</MenuItem>
                          <MenuItem value={'FortDeFrance'}>{'Fort-de-France'}</MenuItem>
                          <MenuItem value={'Grenoble'}>{'Grenoble'}</MenuItem>
                          <MenuItem value={'Limoges'}>{'Limoges'}</MenuItem>
                          <MenuItem value={'Lyon'}>{'Lyon'}</MenuItem>
                          <MenuItem value={'Metz'}>{'Metz'}</MenuItem>
                          <MenuItem value={'Montpellier'}>{'Montpellier'}</MenuItem>
                          <MenuItem value={'Nancy'}>{'Nancy'}</MenuItem>
                          <MenuItem value={'Nîmes'}>{'Nîmes'}</MenuItem>
                          <MenuItem value={'Nouméa'}>{'Nouméa'}</MenuItem>
                          <MenuItem value={'Orleans'}>{'Orléans'}</MenuItem>
                          <MenuItem value={'Papeete'}>{'Papeete'}</MenuItem>
                          <MenuItem value={'Paris'}>{'Paris'}</MenuItem>
                          <MenuItem value={'Pau'}>{'Pau'}</MenuItem>
                          <MenuItem value={'Poitiers'}>{'Poitiers'}</MenuItem>
                          <MenuItem value={'Reims'}>{'Reims'}</MenuItem>
                          <MenuItem value={'Rennes'}>{'Rennes'}</MenuItem>
                          <MenuItem value={'Riom'}>{'Riom'}</MenuItem>
                          <MenuItem value={'Rouen'}>{'Rouen'}</MenuItem>
                          <MenuItem value={'SaintDenis'}>{'Saint-Denis'}</MenuItem>
                          <MenuItem value={'SaintPierreEtMiquelon'}>{'Tribunal supérieur d\'appel de Saint-Pierre-et-Miquelon'}</MenuItem>
                          <MenuItem value={'Toulouse'}>{'Toulouse'}</MenuItem>
                          <MenuItem value={'Versailles'}>{'Versailles'}</MenuItem>

                        </Select>
                      </FormControl>

                    </div>
                  )}
                </>
              )}

              {(isReglementType) && (
                <div style={{ width: '70%' }}>

                </div>
              )}
            </div>



            <div className="flexRow">
              <div style={{ width: '20%' }}>
                <InputMask
                  mask="dD / mM / YYYY"
                  formatChars={formatChars}
                  value={newSource.date}
                  disabled={false}
                  maskChar="_"
                  beforeMaskedValueChange={beforeDateMaskedValueChange}
                  onChange={e => {
                    setNewSource({ ...newSource, date: e.target.value });
                    setErrors({ ...errors, date: null });
                  }}
                >
                  {() => <TextField
                    required
                    variant="filled"
                    style={{ width: '100%' }}
                    label={t('sources.columns.date')}
                    size="small"
                    error={!!errors.date}
                  />}
                </InputMask>
              </div>

              {(isLoi || isDecret) && (
                <>
                  <div style={{ width: '60%' }}>
                    <TextField
                      required
                      variant="filled"
                      style={{ width: '100%' }}
                      value={newSource.article}
                      label={t('sources.columns.article')}
                      onChange={e => setNewSource({ ...newSource, article: e.target.value })}
                      size="small"
                    />
                  </div>

                  <div style={{ width: '20%', marginTop: '0px' }}>

                    <FormControlLabel
                      style={{ display: 'flex', justifyContent: 'right' }}
                      control={
                        <Checkbox
                          checked={newSource.ordrePublic}
                          onChange={e => setNewSource({ ...newSource, ordrePublic: !newSource.ordrePublic })}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="Ordre public"
                    />

                  </div>
                </>
              )}

              {(isDoctrine || isAutre) && (
                <>
                  <div style={{ width: '80%' }}>
                    <TextField
                      required={isDoctrine}
                      variant="filled"
                      style={{ width: '100%' }}
                      value={newSource.auteur}
                      label={t('sources.columns.auteur')}
                      onChange={e => setNewSource({ ...newSource, auteur: e.target.value })}
                      size="small"
                    />
                  </div>
                </>
              )}

              {(isCassation) && (
                <>
                  <div style={{ width: '50%' }}>
                    <InputMask
                      mask="99 - 99.999"
                      value={newSource.numero}
                      disabled={false}
                      maskChar="_"
                      onChange={e => setNewSource({ ...newSource, numero: e.target.value })}
                    >
                      {() => <TextField
                        required
                        variant="filled"
                        style={{ width: '100%' }}
                        label={t('sources.columns.numero')}
                        size="small"
                      />}
                    </InputMask>
                  </div>

                  <div style={{ width: '30%' }}>
                    <FormControl size="small" variant="filled" style={{ width: '100%' }}>
                      <InputLabel>{t('sources.columns.cass_rejet')}</InputLabel>
                      <Select
                        value={newSource.cass_rejet}
                        label={t('sources.columns.cass_rejet')}
                        onChange={e => {
                          const cass_rejet = Number(e.target.value);
                          setNewSource({ ...newSource, cass_rejet });
                        }}
                      >
                        <MenuItem value={'10'}>{t('sources.cass_rejet.10')}</MenuItem>
                        <MenuItem value={'20'}>{t('sources.cass_rejet.20')}</MenuItem>

                      </Select>
                    </FormControl>
                  </div>
                </>
              )}

              {(isJustice && !isCassation) && (
                <>
                  <div style={{ width: '80%' }}>
                    <InputMask
                      mask="99 / 99999"
                      value={newSource.numero}
                      disabled={false}
                      maskChar="_"
                      onChange={e => setNewSource({ ...newSource, numero: e.target.value })}
                    >
                      {() => <TextField
                        required
                        variant="filled"
                        style={{ width: '100%' }}
                        label={t('sources.columns.numero')}
                        size="small"
                      />}
                    </InputMask>
                  </div>
                </>
              )}
            </div>


            <div className="flexRow">
              <div style={{ width: '100%' }}>
                <TextField
                  required
                  multiline
                  minRows={3}
                  variant="filled"
                  style={{ width: '100%' }}
                  value={newSource.texte}
                  label={t('sources.columns.texte')}
                  onChange={e => setNewSource({ ...newSource, texte: e.target.value })}
                  size="small"
                />
              </div>
            </div>

            <div className="flexRow">
              <div style={{ width: '100%' }}>
                <TextField
                  // InputProps={{
                  //   startAdornment: <InputAdornment position="start">
                  //     <span className="material-icons-outlined">assignment</span>
                  //   </InputAdornment>
                  // }}                  
                  variant="filled"
                  style={{ width: '100%' }}
                  value={newSource.mots_supp}
                  label={t('sources.columns.mots_supp')}
                  onChange={e => setNewSource({ ...newSource, mots_supp: e.target.value })}
                  size="small"
                />
              </div>
            </div>


            {(isDoctrine || isAutre || isJustice) && (
              <>
                <div className="flexRow">
                  <div style={{ width: '100%' }}>
                    <TextField
                      variant="filled"
                      style={{ width: '100%' }}
                      value={newSource.lien}
                      label={t('sources.columns.lien')}
                      onChange={e => setNewSource({ ...newSource, lien: e.target.value })}
                      size="small"
                    />
                  </div>
                </div>
              </>
            )}


            <div className="flexRow">
              <div style={{ width: '100%' }}>
                <TextField
                  variant="filled"
                  style={{ width: '100%' }}
                  value={newSource.commentaires}
                  label={t('sources.columns.commentaires')}
                  onChange={e => setNewSource({ ...newSource, commentaires: e.target.value })}
                  size="small"
                />
              </div>
            </div>


            {/* <TextField
              InputProps={{
                startAdornment: <InputAdornment position="start">
                  <span className="material-icons-outlined">account_circle</span>
                </InputAdornment>
              }}
              required
              name="no-username"
              autoComplete="off"
              variant="filled"
              value={newUser.login}
              label={t('admin.accounts.columns.login')}
              onChange={e => setNewUser({ ...newUser, login: e.target.value })}
            />

            <TextField
              InputProps={{
                startAdornment: <InputAdornment position="start">
                  <span className="material-icons-outlined">email</span>
                </InputAdornment>
              }}
              name="no-email"
              autoComplete="off"
              variant="filled"
              value={newUser.email}
              label={t('admin.accounts.columns.email')}
              onChange={e => setNewUser({ ...newUser, email: e.target.value })}
            />

            <div className="flexRow">
              <TextField
                InputProps={{
                  startAdornment: <InputAdornment position="start">
                    <span className="material-icons-outlined">lock</span>
                  </InputAdornment>
                }}
                required
                type="password"
                variant="filled"
                value={newUser.password}
                label={t('reset.field1')}
                onChange={e => setNewUser({ ...newUser, password: e.target.value })}
              />

              <TextField
                InputProps={{
                  startAdornment: <InputAdornment position="start">
                    <span className="material-icons-outlined">lock</span>
                  </InputAdornment>
                }}
                required
                type="password"
                variant="filled"
                value={newUser.passCheck}
                label={t('reset.field2')}
                onChange={e => setNewUser({ ...newUser, passCheck: e.target.value })}
              />
            </div> */}
          </div>
        </PromptWrapper>

        <PromptActions>
          <Button className="btnSecondary" onClick={() => setSelectedSource(null)}>
            {t('global.cancel')}
          </Button>

          <Button className="btnPrimary" type="submit" disabled={loading}>
            {loading ? <BeatLoader color="white" css="transform:translateY(4px)" /> : t('global.create')}
          </Button>
        </PromptActions>
      </form>
    </Prompt>
  );
}
