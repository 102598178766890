import React from 'react';
import { Route, Switch, Redirect } from 'react-router';

import Login from '../Views/Auth/Login';
// import Reset from '../Views/Auth/Reset';
// import Forgot from '../Views/Auth/Forgot';


export default function LoggedOutRoutes() {
  return (
    <>
      <Switch>
        <Route path='/login'>
          <Login/>
        </Route>
        
        {/* <Route path='/forgot'>
          <Forgot/>
        </Route>
        
        <Route path='/reset/:token'>
          <Reset/>
        </Route> */}

        {/* Redirect to login page if not a route */}
        <Route render={() => <Redirect to="/login"/>}/>
      </Switch>
    </>
  );
}
