import React from 'react';

import Header from '../../Components/Dashboard/Header/Header';
import Accounts from '../../Components/Dashboard/Admin/Accounts/List';


export default function AccountsPage() {
  return (
    <>
      <Header/>
      <div className="body">
        <Accounts/>        
      </div>
    </>
  );
}
