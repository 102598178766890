import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import isEqual from 'react-fast-compare';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import BeatLoader from 'react-spinners/BeatLoader';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';

import { Editor } from './Editor/Editor'
import ResultList from './Resultat/Result'


//https://jpuri.github.io/react-draft-wysiwyg/#/demo

import { Button, Grid, Box, Container, Paper, Select, MenuItem, InputLabel, FormControl, withStyles, TextField, Chip } from '@material-ui/core';

// import { DataGrid, useGridApiRef, GridFooter, GridFilterInputValue, frFR, enUS, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid-pro';

import { searchWords } from '../../../Functions/main';
import { colors, roles, useStateWithCallback } from '../../../Shared/utils';

import { useAppContext } from '../../../Context/App/Provider';
import { useMainContext } from '../../../Context/Main/Provider';
import { useAdminContext } from '../../../Context/Admin/Provider';
// import { useSourcesContext } from '../../../Context/Sources/Provider';

// import { formatReportingHeader, colors, roles, useStateWithCallback } from '../../../Shared/utils';
// import { changeReportingColumns, changeSettings, getSelectedLocations, getSelectedGroups, toggleSidebar, translatedGridFilters, activeGridFilter, getPreciseTime, getTimeAsZDateTime } from '../../../Functions/utils';
import SourceDetail from '../Sources/Details/sourceDetails'

import Typography from "@material-ui/core/Typography";
import { makeStyles } from '@material-ui/core/styles';

import './Main.css';

// import { diag_code_ids } from "../../../Shared/diagcode"
// import { devicesTypeValues } from '../../../Shared/utils';


const line_separator = " / ";


export default function Main() {
  const { t } = useTranslation();

  const [{ settings, sidebar, user }, appDispatch] = useAppContext();
  const [{ search, words, result }, dispatch] = useMainContext();
  const [{ accountColumns }, dispatch2] = useAdminContext();

  const [loading, setLoading] = useState(false);
  // console.log("41 - accountColumns=", accountColumns);

  // const [testWords, setTestWords] = useState("");
  // const [value, setValue] = useState('');

  const [detail, setDetail] = useState([]);



  // FOR TEST : IN STATE
  const [results, setResults] = useState();


  const handleSearchWords = (e, wordsList) => {
    e.preventDefault();
    setLoading(true);

    console.log("64 - wordsList=", wordsList);

    // Only set new contact email if changed
    if (!wordsList || (wordsList && wordsList.length === 0))
      return;

    //  setGroups(groups.filter(row => row.id !== group.id));
    // const list = wordsList.filter(word => {
    //   if (word.active)
    //     return word.txt;
    // });
    const list = wordsList.filter(word => word.active).map(word => word.txt);

    console.log("78 - list=", list);
    searchWords({ wordsList: list }).then(res => {

      setLoading(false);
      if (!res.success) return toast(t('admin.accounts.errors'), { type: 'error' });

      console.log("72 - res=", res);

      setResults(res && res.data);
      // const newUsers = users.slice(); // .slice() creates a new array
      // for (const [index, row] of newUsers.entries()) {
      //   if (row.id === res.user.id) {
      //     // newUsers[index] = { ...res.user, role: t('admin.accounts.types.' + res.user.role) }; break;
      //     newUsers[index] = { ...res.user, role: res.user.role }; break;
      //   }
      // }

      // // update default accounts sorting in case of username change
      // setUsers(newUsers.sort((a, b) => a.login.localeCompare(b.login)));

      // setSelectedUser(null);
      // toast(t('admin.accounts.update.success', { login: user.login }), { type: 'success' });
    });
  };


  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 0,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    }
  }));


  const classes = useStyles();

  // console.log("74 - words=", words, words && words.length);

  // words.map((word, key) => {
  //   console.log("77 - word=", word, key)
  // });


  useEffect(() => {
    console.log("120 - results=", results);
    let details;
    if (results) {
      if (results.detail && results.detail.length > 0) {
      }
    }
  }, [results]);


  console.log("117 - loading=", loading);

  const arrayLoi = new Array();
  const mapLoi = new Map();

  const arrayDecret = new Array();
  const mapDecret = new Map();

  const arrayJustice = new Array();
  const mapJustice = new Map();

  const arrayReglement = new Array();
  const mapReglement = new Map();

  const arrayDoctrine = new Array();
  const mapDoctrine = new Map();

  const arrayAutre = new Array();
  const mapAutre = new Map();

  if (results && results.recordset && results.recordset.length > 0) {

    const sortedRecordset = results.recordset.sort(function (a, b) {

      // ordre de priorité loi > decret > ...
      if (a.type > b.type) {
        return 1;
      }
      if (a.type < b.type) {
        return -1;
      }

      // ORDRE ARTICLES DE LOI
      if (a.type === 10 || a.type === 20) {

        if (a.intitule > b.intitule) {
          return 1;
        }
        if (a.intitule < b.intitule) {
          return -1;
        }

        const regex = /[0-9]+/i;  // 
        const itemsA = a.article.match(regex);
        const itemsB = b.article.match(regex);

        if (parseInt(itemsA[0], 10) > parseInt(itemsB[0], 10)) {
          return 1;
        }
        if (parseInt(itemsA[0], 10) < parseInt(itemsB[0], 10)) {
          return -1;
        }

        if (a.article > b.article) {
          return 1;
        }
        if (a.article < b.article) {
          return -1;
        }

        if (a.date < b.date) {
          return 1;
        }
        if (a.date > b.date) {
          return -1;
        }
        return 0;
      }
      return 0;
    })

    console.log("199 - sortedRecordset=", sortedRecordset);
    for (const element of sortedRecordset) {
      if (element.type === 10) {
        arrayLoi.push(element);

        const key = element.intitule + "<!>" + element.article;
        let arrayTmp = mapLoi.get(key);
        if (arrayTmp === undefined) {
          arrayTmp = new Array();
        }
        arrayTmp.push(element);
        mapLoi.set(key, arrayTmp);
      }
      else if (element.type === 20) {
        arrayDecret.push(element);

        const key = element.intitule + "<!>" + element.article;
        let arrayTmp = mapDecret.get(key);
        if (arrayTmp === undefined) {
          arrayTmp = new Array();
        }
        arrayTmp.push(element);
        mapDecret.set(key, arrayTmp);
      }
      else if (element.type === 30) {
        arrayJustice.push(element);

        let key = element.juridiction + "<!>" + element.numero;
        if (element.juridiction === 20)
          key = element.juridiction + "<!>" + element.ville + "<!>" + element.numero;

        let arrayTmp = mapJustice.get(key);
        if (arrayTmp === undefined) {
          arrayTmp = new Array();
        }
        arrayTmp.push(element);
        mapJustice.set(key, arrayTmp);
      }
      else if (element.type === 40)
        arrayReglement.push(element);
      else if (element.type === 50) {
        arrayDoctrine.push(element);

        const key = element.intitule + "<!>" + element.auteur;
        let arrayTmp = mapDoctrine.get(key);
        if (arrayTmp === undefined) {
          arrayTmp = new Array();
        }
        arrayTmp.push(element);
        mapDoctrine.set(key, arrayTmp);
      }
      else if (element.type === 60) {
        arrayAutre.push(element);

        const key = element.intitule + "<!>" + element.auteur;
        let arrayTmp = mapAutre.get(key);
        if (arrayTmp === undefined) {
          arrayTmp = new Array();
        }
        arrayTmp.push(element);
        mapAutre.set(key, arrayTmp);

        arrayAutre.push(element);
      }
    }
  }

  console.log("arrayLoi=", arrayLoi);
  console.log("arrayDecret=", arrayDecret);
  console.log("arrayJustice=", arrayJustice);
  console.log("arrayDoctrine=", arrayDoctrine);
  console.log("arrayAutre=", arrayAutre);


  return (
    <div className="main"
      style={{
        maxWidth: "100%"
      }}
    >

      <div className="MuiDataGrid-root">
        <div className="content__header">
          <h2>{t('main.search')}
          </h2>
        </div>

        <div
        // style={{            
        //   display: 'flex',
        //   alignItems: 'center',
        //   justifyContent: 'space-between',
        //   maxHeight: "calc(100% - 1*56px)",
        // }}
        >
          <Grid
            container
            spacing={3}
          >
            <Grid item xs={5} >

              {/* TEXT FIELD + ADD BUTTON */}
              <div
                style={{
                  gap: "10px",
                  margin: '10px 0px 10px 10px',
                  minHeight: '56px',
                  padding: '10px 10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  border: '1px solid var(--separation-color)',
                  // position: "relative",
                  // width: "100%"
                  top: "142px",
                  position: "fixed",
                  width: "40%"
                }}
              >
                <div className="prompt__content">
                  <TextField
                    name="no-username"
                    autoComplete="off"
                    variant="filled"
                    value={search}
                    label={t('main.wordsToSearch')}
                    onChange={e => {
                      dispatch({ search: e.target.value });
                    }}
                    size="small"
                  />
                </div>

                <Button
                  className="button"
                  onClick={e => {
                    const newWords = [];
                    // console.log("130 - add=", search);

                    const regex = /"(.*?)"/g;  // 
                    const tmpExt = search && search.match(regex);
                    console.log("177 - tmpExt=", tmpExt);

                    if (tmpExt) {
                      for (const element of tmpExt) {

                        if (element === '""')
                          continue;

                        const alreadyIn = newWords.find((word) => {
                          if (word.txt === element) {
                            return element;
                          }
                        });

                        if (words) {
                          const alreadyIn2 = words.find((word) => {
                            if (word.txt === element) {
                              return element;
                            }
                          });

                          if (alreadyIn === undefined && alreadyIn2 === undefined) {
                            const data = {
                              txt: element,
                              active: true
                            }
                            newWords.push(data);
                          }
                        }
                        else {
                          if (alreadyIn === undefined) {
                            const data = {
                              txt: element,
                              active: true
                            }
                            newWords.push(data);
                          }
                        }
                      }
                    }


                    //const regex2 = /(?<![\S"])([^"\s]+)(?![\S"])/g;  // 
                    const regex2 = /([A-Za-zÀ-ÖØ-öø-ÿ]+)(?=(?:[^"]|["][^"]*["])*$)/g;  //                     
                    const tmpExt2 = search && search.match(regex2);
                    console.log("214 - tmpExt2=", tmpExt2);

                    if (tmpExt2) {
                      for (const element of tmpExt2) {

                        if (element.length < 3)
                          continue;

                        const alreadyIn = newWords.find((word) => {
                          if (word.txt === element) {
                            return element;
                          }
                        });

                        if (words) {
                          const alreadyIn2 = words.find((word) => {
                            if (word.txt === element) {
                              return element;
                            }
                          });

                          if (alreadyIn === undefined && alreadyIn2 === undefined) {
                            const data = {
                              txt: element,
                              active: true
                            }
                            newWords.push(data);
                          }
                        }
                        else {
                          if (alreadyIn === undefined) {
                            const data = {
                              txt: element,
                              active: true
                            }
                            newWords.push(data);
                          }
                        }
                      }
                    }
                    const tmpWords = words ? words : [];
                    console.log("257 - tmpWords=", tmpWords.concat(
                      newWords.sort(
                        (a, b) => search.indexOf(a.txt) > search.indexOf(b.txt))
                    ));

                    dispatch({
                      search: "",
                      words: tmpWords.concat(
                        newWords.sort(
                          (a, b) => search.indexOf(a.txt) > search.indexOf(b.txt))
                      )
                    });
                  }}
                  startIcon={<span className="material-icons-round">add_circle_outline</span>}
                >{t('main.add')}
                </Button>
              </div>


              {/* CHIPS ZONE */}
              <div
                style={{
                  overflow: 'auto visible',
                  margin: '10px 0px 10px 10px',
                  padding: '5px 5px 5px 5px',
                  display: 'flex',
                  justifyContent: 'left',
                  border: '1px solid var(--separation-color)',

                  top: "221px",
                  bottom: "77px",
                  position: "fixed",
                  width: "40%"
                }}
              >
                <div
                  style={{
                    justifyContent: 'left',
                    flexWrap: 'wrap'
                  }}>

                  {
                    words && words.length > 0 ? (
                      words.map((word, key) => {
                        return (
                          <Chip
                            style={{ margin: "2px", opacity: word.active ? "1" : "0.2" /*, color: (word.active ? colors.dh: 'green')*/ }}
                            label={word.txt}
                            //clickable={true}
                            color={"primary"}
                            variant="outlined"
                            onDelete={e => {
                              const tmpWords = words.filter(element => element.txt !== word.txt);
                              dispatch({
                                words: tmpWords
                              });
                            }}
                            onClick={e => {
                              const objIndex = words.findIndex((element => element.txt === word.txt));
                              words[objIndex].active = !words[objIndex].active;
                              dispatch({
                                words: words
                              });
                            }
                            }
                          />
                        )
                      })
                    ) : (
                      <div></div>
                    )
                  }
                </div>
              </div>


              {/* CLEAR + SEARCH BUTTONS */}
              <div
                style={{
                  gap: '15px',
                  margin: '10px 0px 10px 10px',
                  minHeight: '56px',
                  padding: '10px 10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid var(--separation-color)',

                  position: "fixed",
                  bottom: "10px",
                  width: "40%"
                }}
              >

                {
                  words && words.length > 0 || true ? (
                    <div>
                      <Button
                        className="button"
                        disabled={!words || (words && words.length === 0)}
                        onClick={e => {
                          // Clear
                          console.log("179 - clear...");
                          dispatch({
                            words: []
                          });
                        }}
                        startIcon={<span className="material-icons-round" style={{ color: '#f22' }}>clear</span>}
                      >{t('main.clear_all')}
                      </Button>

                      <Button
                        className="button"
                        onClick={e => {
                          console.log("189 - search=", words);
                          handleSearchWords(e, words);
                        }}
                        disabled={!words || (words && words.length === 0)}
                        startIcon={<span className="material-icons-round">search</span>}
                      >{t('main.search')}
                      </Button>
                    </div>
                  ) : (
                    <div></div>
                  )
                }

              </div>
            </Grid>


            <Grid item >
              <div className="prompt__content">

                {/* CHIPS ZONE */}

                {loading ? (
                  <div
                    style={{
                      overflow: 'auto visible',
                      margin: '10px 10px 10px 0px',
                      padding: '5px 5px 5px 5px',
                      display: 'flex',
                      justifyContent: 'center',
                      border: '1px solid var(--separation-color)',

                      top: "142px",
                      bottom: "10px",
                      position: "fixed",
                      width: "calc(60% - 50px)",
                      right: "10px"
                    }}
                  >

                    {/* RESULTATS DE RECHERCHE OU RIEN OU ICONE D'ATTENTE */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: 'left',
                        flexWrap: 'wrap'
                      }}>
                      <div className="loader">
                        <BeatLoader color={colors.dh} />
                      </div>

                    </div>
                  </div>
                ) : (

                  <div
                    style={{
                      overflow: 'auto visible',
                      margin: '10px 10px 10px 0px',
                      padding: '5px 5px 5px 5px',
                      display: 'flex',
                      justifyContent: 'left',
                      border: '1px solid var(--separation-color)',

                      top: "142px",
                      bottom: "10px",
                      position: "fixed",
                      width: "calc(60% - 50px)",
                      right: "10px"
                    }}
                  >

                    {/* RESULTATS DE RECHERCHE OU RIEN OU ICONE D'ATTENTE */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: 'left',
                        flexWrap: 'wrap'
                      }}>


                      {results ? (
                        <div>
                          <div>
                            {results.detail.sort(function (a, b) {
                              return (a.count > b.count);
                            }).map((detail, key) => {
                              return <p>{detail.word} ({detail.count})</p>
                            }
                            )}
                          </div>
                          <hr />

                          <div>

                            <h2 className="container__title">
                              <span className="material-icons" style={{ padding: '0px 0px 0px 10px', fontSize: '200%', color: colors.dh }}>arrow_right</span>
                              {t('sources.types.10')}{'\u2000\u2013\u2000'}
                              <span style={{ color: colors.dh }}>
                                {mapLoi.size}
                              </span>
                            </h2>

                            {(mapLoi.size > 0) && (
                              <div>
                                {
                                  Array.from(mapLoi.values()).map((element, key) => {
                                    // console.log("element=", element, key);
                                    return (
                                      <SourceDetail
                                        keyX={key}
                                        arraySource={element}
                                      // handleDelete={handleDelete}
                                      // setSelectedSource={setSelectedSource}
                                      />
                                    )
                                  })
                                }
                              </div>
                            )}

                            <h2 className="container__title">
                              <span className="material-icons" style={{ padding: '0px 0px 0px 10px', fontSize: '200%', color: colors.dh }}>arrow_right</span>
                              {t('sources.types.20')}{'\u2000\u2013\u2000'}
                              <span style={{ color: colors.dh }}>
                                {mapDecret.size}
                              </span>
                            </h2>
                            {(mapDecret.size > 0) && (
                              <div>
                                {
                                  Array.from(mapDecret.values()).map((element, key) => {
                                    // console.log("element=", element, key);
                                    return (
                                      <SourceDetail
                                        keyX={key}
                                        arraySource={element}
                                      />
                                    )
                                  })
                                }
                              </div>
                            )}


                            <h2 className="container__title">
                              <span className="material-icons" style={{ padding: '0px 0px 0px 10px', fontSize: '200%', color: colors.dh }}>arrow_right</span>
                              {t('sources.types.30')}{'\u2000\u2013\u2000'}
                              <span style={{ color: colors.dh }}>
                                {mapJustice.size}
                              </span>
                            </h2>
                            {(mapJustice.size > 0) && (
                              <div>
                                {
                                  Array.from(mapJustice.values()).map((element, key) => {
                                    // console.log("element=", element, key);
                                    return (
                                      <SourceDetail
                                        keyX={key}
                                        arraySource={element}
                                      />
                                    )
                                  })
                                }
                              </div>
                            )}

                            <h2 className="container__title">
                              <span className="material-icons" style={{ padding: '0px 0px 0px 10px', fontSize: '200%', color: colors.dh }}>arrow_right</span>
                              {t('sources.types.40')}{'\u2000\u2013\u2000'}
                              <span style={{ color: colors.dh }}>
                                {mapDoctrine.size}
                              </span>
                            </h2>
                            {(mapDoctrine.size > 0) && (
                              <div>
                                {
                                  Array.from(mapDoctrine.values()).map((element, key) => {
                                    // console.log("element=", element, key);
                                    return (
                                      <SourceDetail
                                        keyX={key}
                                        arraySource={element}
                                      />
                                    )
                                  })
                                }
                              </div>
                            )}

                            <h2 className="container__title">
                              <span className="material-icons" style={{ padding: '0px 0px 0px 10px', fontSize: '200%', color: colors.dh }}>arrow_right</span>
                              {t('sources.types.60')}{'\u2000\u2013\u2000'}
                              <span style={{ color: colors.dh }}>
                                {mapAutre.size}
                              </span>
                            </h2>
                            {(mapAutre.size > 0) && (
                              <div>
                                {
                                  Array.from(mapAutre.values()).map((element, key) => {
                                    // console.log("element=", element, key);
                                    return (
                                      <SourceDetail
                                        keyX={key}
                                        arraySource={element}
                                      />
                                    )
                                  })
                                }
                              </div>
                            )}


                          </div>
                        </div>
                      ) : (
                        <div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

              </div>


            </Grid>

          </Grid>
        </div>
      </div>
    </div >
  );

  // return (
  //   <div className="main"
  //     style={{
  //       maxWidth: "100%"
  //     }}
  //   >
  //     <div className="MuiDataGrid-root"
  //     >
  //       {/* <div className="content__header">
  //         <h2>{t('main.title')}
  //         </h2>

  //         <div className="options">
  //           <Button
  //             className="button"
  //             onClick={e => console.log("click")}
  //             startIcon={<span className="material-icons-round">add_circle_outline</span>}
  //           >{t('main.new')}
  //           </Button>

  //           <Button
  //             className="button"
  //             onClick={e => console.log("click")}
  //             startIcon={<span className="material-icons-round">open_in_browser</span>}
  //           >{t('main.open')}
  //           </Button>

  //           <Button
  //             className="button"
  //             onClick={e => console.log("click")}
  //             startIcon={<span className="material-icons-round">save_alt</span>}
  //           >{t('main.save')}
  //           </Button>

  //         </div>
  //       </div> */}

  //       <div className="content__header">
  //         <h2>{t('main.search')}
  //         </h2>
  //       </div>

  //       <Grid container spacing={1}
  //       >
  //         <Grid item
  //           style={{ width: "66%", height: "calc(100%)", maxHeight: "calc(100%)" }}>

  //           {/* <Grid item xs
  //             style={{
  //               // height: "calc(100% - 56px)",
  //               // minHeight: "calc(100% - 56px)",
  //               // maxHeight: "calc(100% - 56px)",
  //               // maxWidth: "100%",
  //               // boxSizing: "border-box",
  //               // height: "calc(100% - 56px)",
  //               // display: 'flex',
  //               // flexDirection: 'column',
  //               // justifyContent: 'space-between',
  //             }}
  //           >
  //             <Editor />
  //           </Grid> */}

  //           {/* <Grid item xs
  //             style={{ height: '56px', position: 'relative', bottom: '0px' }}
  //           >

  //             <TextField
  //               InputProps={{
  //                 readOnly: true,
  //               }}
  //               name="no-username"
  //               autoComplete="off"
  //               variant="filled"
  //               // value={newUser.login}
  //               label={t('main.wordsToSearch')}
  //               // onChange={e => setNewUser({ ...newUser, login: e.target.value })}
  //               size="small"
  //             />
  //           </Grid> */}
  //         </Grid>



  //         <Grid item xs
  //           style={{ width: "66%", height: "calc(100%)", maxHeight: "calc(100%)" }}
  //         >
  //           <ResultList />

  //         </Grid>

  //       </Grid>


  //       {/* <Grid container spacing={1}>
  //         <Grid item style={{ width: "66%" }}>
  //           <ResultList />
  //         </Grid>
  //         <Grid item xs>
  //           <ResultList />
  //         </Grid>

  //         <Grid item xs>
  //           <ResultList />
  //         </Grid>
  //       </Grid> */}


  //       {/* <Grid container spacing={1}
  //         style={{
  //           // padding: '8px',
  //           height: 'calc(100%)',
  //           maxHeight: 'calc(100%)',
  //           flexWrap: 'unset'
  //         }}

  //       >
  //         <Grid container item xs={8} style={{
  //           // padding: '8px',
  //           height: 'calc(100%)',
  //           maxHeight: 'calc(100%)'
  //         }}>
  //           <Editor />
  //         </Grid>

  //         <Grid container item xs={4}>
  //           <ResultList />
  //         </Grid>
  //       </Grid>

  //       <Grid container spacing={1}
  //         style={{
  //           // padding: '8px',
  //           height: 'calc(100%)',
  //           maxHeight: 'calc(100%)',
  //           // flexWrap: 'unset'
  //         }}
  //       >

  //         <Grid container item xs={8}>
  //           <ResultList />
  //         </Grid>
  //       </Grid> */}

  //     </div>
  //   </div >
  // );
}
