import React, { createContext, useContext, useReducer } from 'react';

const sourcesState = (
  localStorage.getItem('sourcesState')
    ?
    JSON.parse(localStorage.getItem('sourcesState'))
    :
    {
      expanded: [],
      tabValueId: [],
      typeExpanded: []
    }
);

const SourcesContext = createContext();
export const SourcesProvider = ({ reducer, children }) => (
  <SourcesContext.Provider value={useReducer(reducer, sourcesState)}>
    {children}
  </SourcesContext.Provider>
);

export const useSourcesContext = () => useContext(SourcesContext);