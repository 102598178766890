import i18next from 'i18next';
import ReactDOM from 'react-dom';
import React, { Suspense } from 'react';
import { initReactI18next } from 'react-i18next';
import LoadTranslations from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import App from './Components/App';

import { appReducer } from './Context/App/reducer';
import { AppProvider } from './Context/App/Provider';

import { adminReducer } from './Context/Admin/reducer';
import { AdminProvider } from './Context/Admin/Provider';

import { mainReducer } from './Context/Main/reducer';
import { MainProvider } from './Context/Main/Provider';

import { sourcesReducer } from './Context/Sources/reducer';
import { SourcesProvider } from './Context/Sources/Provider';


i18next // translation module
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(LoadTranslations)
  .init({
    backend: {
      loadPath: '/assets/locales/{{lng}}.json'
    },
    detection: {
      caches: ['localStorage'],
      lookupLocalStorage: 'lang',
      order: ['localStorage', 'navigator']
    },
    fallbackLng: 'fr',
    supportedLngs: ['fr']
  });

// Show blank page for the couple of milliseconds translations take to load
const loading = {
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100wv',
  height: '100vh',
  position: 'absolute',
  backgroundColor: '#eee'
};

ReactDOM.render( // Stack of the different contexts
  <React.StrictMode>
    <Suspense fallback={<div style={loading} />}>
      <AppProvider reducer={appReducer}>
        <MainProvider reducer={mainReducer}>
          <SourcesProvider reducer={sourcesReducer}>
            <AdminProvider reducer={adminReducer}>
              <App />
            </AdminProvider>
          </SourcesProvider>
        </MainProvider>
      </AppProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);
