import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { roles } from '../../../Shared/utils';
import { logout } from '../../../Functions/auth';
import { useAppContext } from '../../../Context/App/Provider';

import './Header.css';


export default function Header() {
  const { t } = useTranslation();
  const [{ version, sidebar, user }] = useAppContext();

  const handleLogout = () => logout().then(() => {
    localStorage.removeItem('signedIn');
    return window.location.pathname = '/'; // Reset context to the initial state
  });


  useEffect(() => {
    const dropdown = (e) => {
      if (typeof e.target.className === 'string' && !e.target.className.includes('dropdown')) {

        document.querySelector('#user') && document.querySelector('#user').classList.remove('open');
        document.querySelector('#admin') && document.querySelector('#admin').classList.remove('open');
        document.querySelector('#sources') && document.querySelector('#sources').classList.remove('open');
      }
    };
    document.addEventListener('click', dropdown);

    return () => document.removeEventListener('click', dropdown);
  }, [t]);


  let circleColor = '';
  if (user.role === roles.admin)
    circleColor = 'rgb(var(--accent-color))';
  else if (user.role === roles.superAdmin)
    circleColor = '#f1c40f';

  // console.log("42 - user=", user);


  return (
    <div className="header">
      <div className={sidebar ? 'branding' : 'branding hidden'}>

        <img
          style={{
            padding: "0px 20px 0px 20px",
            maxWidth: "80%",
            // maxHeigh: "56px",
            width: "var(--sidebar-width)",
            // minHeight: "var(--header-height)",
            height: "auto",

            display: "flex",
            alignItems: "center",
            // position: "absolute",
            top: "0px",
            left: "0px"
          }}
          src="/assets/images/Logo_TFE_Page_principale.jpg" alt="Logo"
        />

        {/* <img src="/assets/images/logo_coprogeom.png" alt="Logo" /> */}

        {/* <span>
          {t('global.dashboard')}
          {(version && version.version) && (
            <span style={{ display: "grid", fontSize: "12px" }}>
              {t('global.version_letter')}
              {version.version}
            </span>
          )}
        </span> */}
      </div>

      <div className="nav">
        <nav>
          <Link to="/main" className={window.location.pathname.startsWith('/main') ? 'selected' : null}>
            {/* <img style={{ width: "24px" }} src="/assets/images/icons_png/document.png" alt=""></img> */}
            <span className="material-icons-round">source</span>
            <span>{t('header.main')}</span>
          </Link>

          <Link to="/sources" className={window.location.pathname.startsWith('/sources') ? 'selected' : null}>
            {/* <img style={{ width: "24px" }} src="/assets/images/icons_png/balance.png" alt=""></img> */}
            <span className="material-icons-round">balance</span>
            <span>{t('header.sources')}</span>
          </Link>


          {user.role <= roles.admin && (
            <div className={window.location.pathname.startsWith('/admin') ? 'dropdown selected' : 'dropdown'}>
              <ul className="dropdown__list" id="admin" style={{ left: 0 }}>
                <li><Link to="/admin/accounts">{t('header.admin.accounts')}</Link></li>
              </ul>

              <div className="dropdown__content"
                onClick={() => {
                  // document.querySelector('#sources').classList.toggle('close');
                  document.querySelector('#admin').classList.toggle('open')
                }}
              >
                {/* <span className="dropdown__open dropdown__icon">
                  <img style={{ width: "24px" }} src="/assets/images/icons_png/parameters.png" alt=""></img>
                </span> */}
                <span className="dropdown__open material-icons-outlined dropdown__icon">settings</span>
                <span className="dropdown__open dropdown__title">
                  {
                    window.location.pathname.startsWith('/admin') ? (
                      t('header.admin.' + (window.location.pathname.slice(window.location.pathname.lastIndexOf('/') + 1) || 'accounts'))
                    ) : (
                      t('header.admin.admin')
                    )
                  }
                </span>
                <span className="dropdown__open material-icons-round dropdown__arrow">expand_more</span>
                {/* <span className="dropdown__open dropdown__arrow">
                  <img style={{ width: "32px" }} src="/assets/images/icons_png/expand.png" alt=""></img>
                </span> */}

              </div>
            </div>
          )}
        </nav>

        <div className={window.location.pathname.startsWith('/settings') ? 'dropdown selected' : 'dropdown'}>
          <ul className="dropdown__list" id="user">
            {/* <li><Link to="/settings">{t('header.user.settings')}</Link></li> */}
            {/* <li onClick={o => setShowSettings(true)}><span>{t('header.user.settings')}</span></li> */}
            <li onClick={handleLogout}><span>{t('header.user.logout')}</span></li>
          </ul>

          <div className="dropdown__content" onClick={() => document.querySelector('#user').classList.toggle('open')}>
            <span className="dropdown__open material-icons-outlined dropdown__icon" style={{ color: circleColor }}>account_circle</span>
            <span className="dropdown__open dropdown__title">{user.login.includes('@') ? user.login.slice(0, user.email.indexOf('@')) : user.login}</span>
            <span className="dropdown__open material-icons-round dropdown__arrow">expand_more</span>
          </div>
        </div>
      </div >
    </div >
  );
}
