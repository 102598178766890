import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import BeatLoader from 'react-spinners/BeatLoader';
import { BrowserRouter as Router } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';

import 'react-toastify/dist/ReactToastify.css';
import { toast, Slide, ToastContainer } from 'react-toastify';


import { logout } from '../Functions/auth';
import { getUserData, checkUserToken } from '../Functions/user';
import { colors, roles, values } from '../Shared/utils';

import { useAppContext } from '../Context/App/Provider';
import { useMainContext } from '../Context/Main/Provider';

import './App.css';

import LoggedInRoutes from '../Routes/LoggedInRoutes';
import LoggedOutRoutes from '../Routes/LoggedOutRoutes';

const versionData = require('../version.json')


export default function App() {
  const { t } = useTranslation();
  const [{ user, version }, appDispatch] = useAppContext();
  const [{ }, rptDispatch] = useMainContext();

  const [loaded, setLoaded] = useState(!!user);

  const initialize = useCallback((user) => {
    if (!version) {
      appDispatch({ version: versionData });
    }
  }, [appDispatch]);


  useEffect(() => {
    // console.log("41 - useEffect=", loaded);
    if (!loaded) {
      setLoaded(true);

      // Redirect to correct route after login
      sessionStorage.setItem('redirect', window.location.pathname);

      // Check signed in state
      const signedIn = localStorage.getItem('signedIn');
      if (!signedIn) return appDispatch({ user: {} });
      
      // Retrieve user data on first load
      getUserData().then(res => {
        if (!res.success) logout().then(() => appDispatch({ user: {} }));

        else {
          
          const settings = {
            ...values.defaultSettings,
            ...JSON.parse(res.user.settings)
          };
          if (settings.lang !== localStorage.getItem('lang'))
            i18next.changeLanguage(settings.lang);

          initialize(res.user);
          appDispatch({ settings, user: res.user });
        }
      });
    }
  }, [loaded, appDispatch, initialize, t]);


  // console.log("79 - user=", user);
  return (
    <div className="app" id="app">
      {user ? (
        <Router>
          {user.id ? <>
            <LoggedInRoutes />
          </> : <>
            <LoggedOutRoutes />
          </>}
        </Router>
      ) : (
        <div className="loader">
          <BeatLoader color={colors.dh} />
        </div>
      )}

      <ToastContainer
        draggable
        closeOnClick
        pauseOnHover
        theme="colored"
        pauseOnFocusLoss
        autoClose={3000}
        transition={Slide}
      />
    </div>
  );
}