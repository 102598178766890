import React from 'react';
import { Route, Switch, Redirect } from 'react-router';

import { roles } from '../Shared/utils';
import { useAppContext } from '../Context/App/Provider';


import Main from '../Views/Main';

import Sources from '../Views/Sources';
import EditSource from '../Views/Sources';

import Accounts from '../Views/Admin/Accounts';


export default function LoggedInRoutes() {
  const [{ user }] = useAppContext();

  return (
    <Switch>
      <Route path='/main'>
        <Main />
      </Route>

      <Route exact path='/sources'>
        <Sources/>
      </Route>

      <Route exact path='/sources/:id'>
        <EditSource/>
      </Route>

      {/* Requires each condition to be separate to avoid console warnings */}
      {user.role >= roles.superAdmin && <Route exact path={['/admin/accounts', '/admin']}>
        <Accounts />
      </Route>}


      {/* Redirect to the home page if the route doesn't exist */}
      <Route render={() => <Redirect to="/main" />} />
    </Switch>
  );
}